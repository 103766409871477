import React from 'react'
import ReCAPTCHA from "react-google-recaptcha";

let vector = [0]
let count_file = -1

const Content = function content ({
    text,
    onChange,form,submit,recaptchaRef,terms,
    add_file,job
}){
    count_file = -1
    return (
        <section className="request">
            <div className="container colflex">
                <div className="text colflex">
                    <h1>
                        <span>
                            Aplicar a la vacante 
                        </span>
                        <br/>
                        {job.title}
                    </h1>
                    <p dangerouslySetInnerHTML={{__html: job.text}}></p>
                </div>

                <form className="colflex" id="formContact">
                    <div className="denun item colflex">
                        <h2>Información para aplicar</h2>
                    </div>

                    <div className="item colflex">
                        <p>Nombre Completo</p>
                        <input 
                            type="text" 
                            placeholder="Escriba aquí..."
                            name="name"
                            value={form.name}
                            onChange={onChange}
                        />
                        <hr className="separator"/>
                        <p>Correo Electronico</p>
                        <input 
                            type="text" 
                            placeholder="Escriba aquí..."
                            name="mail"
                            value={form.mail}
                            onChange={onChange}
                        />
                        <hr className="separator"/>
                        <p>Celular</p>
                        <input 
                            type="text" 
                            placeholder="Escriba aquí..."
                            name="phone"
                            value={form.phone}
                            onChange={onChange}
                        />
                    </div>

                    <div className="item colflex">
                        <h2>DOCUMENTO ADJUNTO</h2>
                        <p>Agregue a su Hoja de Vida</p>
                        {form.file.map((f)=>{
                            count_file++
                            let data = count_file
                            return(
                                <div className="file flex" key={count_file}>
                                    <span>Hoja de vida - {f[2]}</span>
                                </div>
                            )
                        })}
                        <div className="upload flex relative">
                            <input 
                                id="add_file" 
                                name="add_file"
                                type="file" 
                                placeholder="Agregar otro" 
                            />
                        </div>
                        <a onClick={()=>add_file()} className="button_add">
                            Agregar Archivo
                        </a>
                    </div>

                    <div className="item colflex">
                        <p>Mensaje <span>(opcional).</span></p>
                        <textarea 
                            rows="6"
                            placeholder="Escriba aquí..."
                            name="messague"
                            value={form.messague}
                            onChange={onChange}
                        ></textarea>
                    </div>

                    <div className="item colflex">
                        <h2>TÉRMINOS Y CONDICIONES</h2>
                        <p>Términos y condiciones legales:</p>
                        <div className="policy colflex">
                            <div className="text grid">
                                <input
                                    id="check_terms"
                                    type="checkbox"
                                    onClick={()=>terms()}
                                />
                                <label htmlFor="check_terms">Al marcar la casilla, se entiende que ha leído, aceptado y autorizado el Tratamiento de sus Datos Personales de acuerdo con nuestra Política de Tratamiento de Información. 
                                <a href={"/autorizacion-tratamiendo-de-datos-personales"} target='_blank'>
                                    Ver Autorización Tratamiento De Datos Personales.
                                </a>
                                </label>
                            </div>
                            <a onClick={()=>submit()} className="btnFormModal">
                                ENVIAR
                            </a>
                        </div>
                    </div>

                </form>
            </div>
        </section>
    )
}

export default Content