import React from 'react'

let vector = [0]

const Banner = function banner ({url,service}){
    return (
        <section className="bannerService">
            <div className="content colflex">
                <img src={url+service.img} alt="image-train"/>
                <div className="info colflex">
                    <h1>{service.title}</h1>
                    <p dangerouslySetInnerHTML={{__html: service.text}}></p>
                </div>
            </div>
        </section>
    )
}

export default Banner