import React from 'react'

let vector = [0]
var vector_pages = [];

const Blog = function blog ({
    url,text,list,
    prevHandler,nextHandler,currentPage,changeHandler,pages,
    }){

    let pagination = ""
    let page = ""
    let count_pages = 0
    
    if(parseInt(pages) >= 1){
        let vector_pages = [];
        for (let i = 0; i<pages; i++){
            vector_pages[i] = i
        }
        pagination = vector.map((v)=>{
            return (
                <div className="paginator container flex" key={1}>
                    <img onClick={prevHandler} src="/img/icons/back.svg" alt="⬅️"/>
                    <div className="numbers flex">
                        {vector_pages.map((v)=>{
                            return (
                                <span
                                    className={currentPage == v?"selected":""}  
                                    onClick={() => changeHandler(v)} 
                                    key={v}
                                >
                                    {v+1}
                                </span>
                            )
                        })}
                    </div>
                    <img onClick={nextHandler} src="/img/icons/next.svg" alt="➡️"/>
                </div>
            )
        })
    }else{
        vector_pages = [];
        vector_pages[0] = 0
    }

    return (
        <section className="blog">
            <div className="container colflex">
                <h1>{text.title}</h1>
                <p dangerouslySetInnerHTML={{__html: text.text}}></p>
                <div className="postList grid" id="allProducts">
                    {list.map((l)=>{
                        let date = l.created_at.split("T")
                        return(
                            <div className="item colflex" key={l.id}>
                                <a href={"/lo-ultimo/"+l.slug} className="thumb flex">
                                    <img src={url+l.img} alt="post-img"/>
                                </a>
                                <div className="text colflex">
                                    <a href={"/lo-ultimo/"+l.slug}>{l.title}</a>
                                    <span>{date[0]}</span>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>

            {pagination}
        </section>
    )
}

export default Blog