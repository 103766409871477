import React from 'react'

let vector = [0]
var vector_pages = [];

const List = function list ({
  url,url_redirect,list,
  prevHandler,nextHandler,currentPage,changeHandler,pages,
  }){
  let pagination = ""
  let page = ""
  let count_pages = 0
  
  if(parseInt(pages) >= 1){
      let vector_pages = [];
      for (let i = 0; i<pages; i++){
          vector_pages[i] = i
      }
      pagination = vector.map((v)=>{
          return (
              <div className="paginator flex" key={1}>
                  <img onClick={prevHandler} src="/img/icons/back.svg" alt="⬅️"/>
                  <div className="numbers flex">
                      {vector_pages.map((v)=>{
                          return (
                              <span
                                  className={currentPage == v?"selected":""}  
                                  onClick={() => changeHandler(v)} 
                                  key={v}
                              >
                                  {v+1}
                              </span>
                          )
                      })}
                  </div>
                  <img onClick={nextHandler} src="/img/icons/next.svg" alt="➡️"/>
              </div>
          )
      })
  }else{
      vector_pages = [];
      vector_pages[0] = 0
  }
  return (
      <section className="listEquipment1" id="allProducts">
        <div className="content">
          {list.map((l)=>{
            return(
              <a href={url_redirect+l.id} className="item flex" key={l.id}>
                <img src={l.img} alt="img-at"/>
                <div className="text colflex"> 
                  <span>{l.title}</span>
                  <img src="/img/equipment/arrow.svg" alt="➡️"/>
                </div>
              </a>
            )
          })}

        </div>
        
        {pagination}
      </section>
  )
}

export default List