import React from 'react'

let vector = [0]

const Video = function video ({url,video}){
    return (
        <section className="videoService2" style={{backgroundImage:"url('"+url+video.img+"')"}}>
          <div className="container colflex">
            <iframe src={"https://www.youtube.com/embed/"+video.video} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            <div className="text colflex">
              <h1>{video.title}</h1>
              <p dangerouslySetInnerHTML={{__html: video.text}}></p>
            </div>
          </div>
        </section>
    )
}

export default Video