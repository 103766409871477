import React from 'react'

let vector = [0]
let validate = 1
let count = 0

const Gallery = function gallery ({text,url,list,text_image}){
    return (
        <section className="galleryService">
            <div className='image_labs' style={{display:"none"}}>
                <h1>{text_image.title} <strong> {text_image.subtitle}</strong></h1>
                <img src={url+text_image.img} />
            </div>
            <div className="content">
                <div className="text colflex">
                    <h1>{text.title}</h1>
                    <p>{text.text}</p>
                </div>
                <div className="galService" id="galService">
                    {list.map((l)=>{
                        return(
                            <img key={l.id} src={url+l.img} alt="img-service"/>
                        )
                    })}
                </div>
                {vector.map((v)=>{
                    if(list.length > 0 && validate == 1){
                    //if(validate == 1){
                        validate = 2
                        setTimeout(()=>{
                            $('#galService').slick({
                                slidesToShow: 1,
                                arrows: false,
                                mobileFirst: true,
                                centerMode: true,
                                responsive: [
                                  {
                                    breakpoint: 700,
                                      settings: 'unslick'
                                  },
                                ]
                            });
                        },10)
                    }
                })}
                <div className="galServiceBig">
                    <div className="contentGalBig grid">
                        {list.map((l)=>{
                            count++
                            if(count > 5){
                                return(
                                    <img key={l.id} src={url+l.img} data-fancybox="gal-service" alt="item-gal-service" style={{display:"none"}}/>
                                )
                            }else{
                                return(
                                    <img key={l.id} src={url+l.img} data-fancybox="gal-service" alt="item-gal-service"/>
                                )
                            }
                        })}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Gallery