import React from 'react'

let vector = [0]

const Banner = function banner ({url,banner_info}){
    return (
        <section className="bannerEquipment">
          <div className="content colflex">
            {vector.map((v)=>{
              if(url == ""){
                return(
                  <img src={url+banner_info.img_marketing_hero} alt="" key={v}/>
                )
              }else{
                return(
                  <img src={url+banner_info.img} alt="" key={v}/>
                )
              }
            })}
            <div className="info colflex">
              <h1>{banner_info.title}</h1>
              <p>{banner_info.text}</p>
            </div>
          </div>
        </section>
    )
}

export default Banner