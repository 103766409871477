import React from 'react'

let vector = [0]
let validate = 1
let count = 0

const Gallery = function gallery ({url,text_image}){
    return (
        <section className="fluids">
            <div className='container colflex'>
                <h1>{text_image.title} 
                    <strong> {text_image.subtitle}</strong>
                </h1>
                <img src={url+text_image.img} />
            </div>
        </section>
    )
}

export default Gallery