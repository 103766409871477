window.https = window.location.host + '/';
window.imageURL = '/img/logo.svg';

var _ = require('lodash');
window.$ = window.jQuery = require('jquery');

//require('materialize-css');
require('sweetalert');
require('slick-carousel');
require('jquery-modal')

require('./views');
require('./libreries/configuration');