import React from 'react'

let vector = [0]
let validate = 1

const Services = function services ({url,text,list}){
    return (
        <section className="servicesHome">
            <div className="content colflex">
                <h1><span>{text.title} </span>{text.subtitle}</h1>
                <p dangerouslySetInnerHTML={{__html: text.text}}></p>
                <div className="listServicesHome" id="listServicesHome">
                    {list.map((l)=>{
                        return(
                            <div className="item relative" key={l.id}>
                                <img src={url+l.img} alt="image"/>
                                <div className="text flex">
                                    <a href={l.url} className="flex">
                                        <span>{l.title}</span>
                                        <svg width="18" height="16" viewBox="0 0 18 16" fill="none">
                                        <path d="M17.7071 8.70711C18.0976 8.31658 18.0976 7.68342 17.7071 7.29289L11.3431 0.928933C10.9526 0.538409 10.3195 0.538409 9.92893 0.928933C9.53841 1.31946 9.53841 1.95262 9.92893 2.34315L15.5858 8L9.92893 13.6569C9.53841 14.0474 9.53841 14.6805 9.92893 15.0711C10.3195 15.4616 10.9526 15.4616 11.3431 15.0711L17.7071 8.70711ZM-8.74228e-08 9L17 9L17 7L8.74228e-08 7L-8.74228e-08 9Z" fill="white"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        )
                    })}
                </div>
                {vector.map((v)=>{
                    if(list.length > 0 && validate == 1){
                        setTimeout(()=>{
                            $('#listServicesHome').slick({
                                slidesToShow: 1,
                                arrows: false,
                                mobileFirst: true,
                                centerMode: true,
                                responsive: [
                                {
                                    breakpoint: 700,
                                    settings: 'unslick'
                                },
                                ]
                            });
                        },10)
                    }
                })}
            </div>
        </section>
    )
}

export default Services