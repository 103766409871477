import React from 'react'

let vector = [0]
let validate = 1

const Banner = function banner ({url,info}){
    return (
        <section className="bannerHome relative banner_spare_parts">
            <img src="/img/back.svg" alt="⬅️" className="dnone backBanner absolute" style={{display:"none"}}/>
            <img src="/img/next.svg" alt="➡️" className="dnone nextBanner absolute" style={{display:"none"}}/>
            <div className="sliderBanner" id="bannerHome">
                <div className="item relative">
                    <a> 
                        <picture className="flex">
                            <source media="(min-width:450px)" srcSet={url+info.img}/>
                            <img src={url+info.img} alt="imageBanner"/>
                        </picture>
                        <div className="backText absolute colflex">
                            <h3>
                            {info.text}
                            </h3>
                            <a className="button_more" href={info.url}>Comprar en línea</a>
                        </div>
                    </a>
                </div>
            </div>
            {vector.map((v)=>{
                //if(list.length > 0 && validate == 1){
                    setTimeout(()=>{
                        $('#bannerHome').slick({
                            slidesToShow: 1,
                            arrows: false,
                            dots: true,
                            mobileFirst: true,
                            autoplay: true,
                            autoplaySpeed: 8000,
                            responsive: [
                                {
                                breakpoint: 1250,
                                    settings: {
                                    arrows: true,
                                    prevArrow: '.backBanner',
                                    nextArrow: '.nextBanner',
                                    dots: true,
                                    }
                                },
                            ]
                        });
                    },10)
                //}
            })}
        </section>
    )
}

export default Banner