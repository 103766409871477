import React,{useRef,Component} from 'react';
import { createRoot } from "react-dom/client";

import Header from "../components/header";
import Menu from "../components/menu";
import Footer from "../components/footer";

import Login from "../components/login";
import Float from "../components/float";

import Content from "../components/jobs/content";
import Modal from "../components/jobs/modal";

const rootElement = document.getElementById("content_jobs");
const root = createRoot(rootElement);

//pagination configuration
let ITEMS_FOR_PAGES = 6
let datosFromApi = []
let items = []
let currentPage = 0
let pages = 0

export default class Jobs extends Component {
    constructor(props){
        super(props)

        this.state = {
            name: 'jobs',
            url_admin:[],
            info:[],
            products:[],
            services:[],
            config_form:[],
            error: null,
            text: [],
            jobs: [],
            send_what: [],
            send_face: [],
            send_twi: [],
            form_float:{
                name: '',
                mail: '',
                phone: '',
                city: '',
                company: '',
                messague: '',
                token:'',
                check_terms: 'no',
                page: '',
                send_mail: '',
            }
        }

        this.nextHandler = this.nextHandler.bind(this)
        this.prevHandler = this.prevHandler.bind(this)
        this.changeHandler = this.changeHandler.bind(this)

        this.openModal = this.openModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.closeModal_2 = this.closeModal_2.bind(this)

        this.copyUrl = this.copyUrl.bind()
        this.apiWhat = this.apiWhat.bind()
        this.apiFace = this.apiFace.bind()
        this.apiTwi = this.apiTwi.bind()

        this._recaptchaRef = React.createRef()
        this.handleChangeFormFloat = this.handleChangeFormFloat.bind(this)
        this.formSubmitFloat = this.formSubmitFloat.bind(this)
        this.submitClickFloat = this.submitClickFloat.bind(this)
        this.termsFloat = this.termsFloat.bind(this)
    }

    async componentDidMount(){
        try{
            let res = await fetch('/get/jobs')
            let data = await res.json()

            datosFromApi = data[6]
            pages = data[6].length / ITEMS_FOR_PAGES
            items = [...data[6]].splice(currentPage,ITEMS_FOR_PAGES)

            this.setState({
                url_admin:data[0],
                info:data[1],
                products:data[2],
                services:data[3],
                config_form:data[4],
                text:data[5][7],
                jobs:items,
                form_float:{
                    ...this.state.form_float,
                    page: data[4].page,
                    send_mail: data[4].mail
                }
            })
        }catch (error) {
            this.setState({
                error
            })
        }
    }

    nextHandler(){
        const totalElementos = datosFromApi.length
        const nextPage = currentPage + 1
        const firstIndex = nextPage * ITEMS_FOR_PAGES
        if(firstIndex >= totalElementos) return
        items = [...datosFromApi].splice(firstIndex,ITEMS_FOR_PAGES)
        this.setState({
            jobs:items
        })
        currentPage = nextPage
        $('html, body').animate({
            scrollTop: $('#allProducts').offset().top - 150
        }, 100);
    }

    prevHandler(){
        const prevPage = currentPage - 1    
        if (prevPage < 0) return
        const firstIndex = prevPage * ITEMS_FOR_PAGES
        items = [...datosFromApi].splice(firstIndex,ITEMS_FOR_PAGES)
        this.setState({
            jobs:items
        })
        currentPage = prevPage
        $('html, body').animate({
            scrollTop: $('#allProducts').offset().top - 150
        }, 100);
    }

    changeHandler(id){
        const firstIndex = id * ITEMS_FOR_PAGES
        items = [...datosFromApi].splice(firstIndex,ITEMS_FOR_PAGES)
        this.setState({
            jobs:items
        })
        currentPage = id
        $('html, body').animate({
            scrollTop: $('#allProducts').offset().top - 150
        }, 100);
    }

    openModal = async (title,location_href) =>{
        $('#modalShare').css('display','inline-block')
        this.setState({
            send_what:"https://api.whatsapp.com/send?text=Hola, te comparto esta vacante en relianz "+title+" "+location_href,
            send_face:"https://www.facebook.com/sharer/sharer.php?u="+location_href,
            send_twi:"https://twitter.com/intent/tweet?url="+location_href
        })
    }

    closeModal = async (e) =>{
        if (document.getElementById('modalShare_m').contains(e.target)){
        }else{
            $('#modalShare').css('display','none')
        }
    }

    closeModal_2 = async () =>{
        $('#modalShare').css('display','none')
    }

    copyUrl(){
        var $temp = $("<input>");
        var $url = $(location).attr('href');

        $("body").append($temp);
        $temp.val($url).select();
        document.execCommand("copy");
        $temp.remove();
        
        swal({
            title: "¡Excelente!",
            text: "¡Enlace Copiado!",
            icon: imageURL,
        });
    }


    apiWhat = async () =>{
        window.open(this.state.send_what, '_blank');
    }
    apiFace = async () =>{
        window.open(this.state.send_face, '_blank');
    }
    apiTwi = async () =>{
        window.open(this.state.send_twi, '_blank');
    }

    handleChangeFormFloat(e){
        this.setState({
            form_float:{
                ...this.state.form_float,
                [e.target.name]:e.target.value
            }
        })
    }

    formSubmitFloat = async (e) => {
        if(
            this.state.form_float.name == '' ||
            this.state.form_float.mail == '' ||
            this.state.form_float.phone == '' ||
            this.state.form_float.city == '' ||
            this.state.form_float.company == '' 
        ){
            swal({
                text: "Debes digitar los datos necesarios",
                icon: imageURL,
                button: "OK!",
            });
            return false;
        }

        if(this.state.form_float.check_terms == 'no'){
            swal({
                text: "Debes aceptar los terminos y condiciones",
                icon: imageURL,
                button: "OK!",
            });
            return false;
        }

        swal({
            text: "Espere un momento",
            icon: imageURL,
            button: false,
            closeOnClickOutside: false,
        });

        if(this.state.form_float.token == ""){
            const token = await this._recaptchaRef.current.executeAsync();
            this.setState({
                form_float:{
                    ...this.state.form_float,
                    token: token
                }
            });
            if(!token){
                swal({
                    text: "Error, eres un robot",
                    icon: "error",
                    button: "OK!",
                    closeOnClickOutside: false,
                });
                return false;
            }
        }

        try{
            let config = {
                method: 'POST',
                headers:{
                    'Accept':'application/json',
                    'Content-Type':'application/json'
                },
                body: JSON.stringify(this.state.form_float)
            }
            let res = await fetch(`/api/contact-float`,config)
            let data = await res.json()
            if(data == 1){
                this.setState({
                    form_float:{
                        name: '',
                        mail: '',
                        phone: '',
                        city: '',
                        company: '',
                        messague: '',
                        token:'',
                        check_terms: 'no',
                        page: '',
                        send_mail: '',
                    }
                })
                document.getElementById("form_float").reset();
                swal.close();
                swal({
                    text: "Gracias por contactarnos",
                    icon: "success",
                    button: "OK!",
                    dangerMode: false,
                    closeOnClickOutside: false,
                })
                    .then((willDelete) => {
                        if (willDelete) {
                            location.reload();
                        } else {

                        }
                    });
            }else{
                console.log(data)
                alert('ocurrio un error');
                setTimeout(function (){
                    //location.reload();
                },1000)
            }
        }catch (error) {
            this.setState({
                error
            })
        }
    }

    submitClickFloat(){
        this.formSubmitFloat()
    }

    termsFloat(){
        let value = ""
        if(this.state.form_float.check_terms == "no"){
            $('#check_terms_float').prop('checked',true)
            value = "si"
        }else{
            $('#check_terms_float').prop('checked',false)
            value = "no"
        }
        this.setState({
            form_float:{
                ...this.state.form_float,
                check_terms:value
            }
        })
    }

    render() {
        return (
            <div>
                <Header
                    products={this.state.products}
                    services={this.state.services}
                />
                <Menu
                    products={this.state.products}
                    services={this.state.services}
                />
                <Login
                />
                <main>
                    <Content
                        url={this.state.url_admin}
                        text={this.state.text}
                        list={this.state.jobs}

                        prevHandler={this.prevHandler}
                        nextHandler={this.nextHandler}
                        currentPage={currentPage}
                        changeHandler={this.changeHandler}
                        pages={pages}

                        openModal={this.openModal}
                    />
                    <Modal
                        closeModal={this.closeModal}
                        copyUrl={this.copyUrl}
                        closeModal_2={this.closeModal_2}

                        apiWhat={this.apiWhat}
                        apiFace={this.apiFace}
                        apiTwi={this.apiTwi}
                    />
                </main>
                <Float
                    config_form={this.state.config_form}

                    onChange={this.handleChangeFormFloat}
                    form={this.state.form_float}
                    submit={this.submitClickFloat}
                    recaptchaRef={this._recaptchaRef}
                    terms={this.termsFloat}
                />
                <Footer
                    products={this.state.products}
                    services={this.state.services}
                    info={this.state.info}
                />
            </div>
        );
    }

}

if (document.getElementById('content_jobs')) {
    root.render(
        <Jobs />
    )
}