import React from 'react'

let vector = [0]

const Banner = function banner ({url,text}){
    return (
        <section className="bannerSustain">
            <div className="content relative">
                <picture className="flex">
                    <source media="(min-width:768px)" srcSet={url+text.img}/>
                    <img src={url+text.img} alt="banner-sustain"/>
                </picture>
                <h1> {text.title} <span>{text.subtitle}</span></h1>
            </div>
        </section>
    )
}

export default Banner