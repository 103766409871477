import React from 'react'

let vector = [0]

const Video = function video ({url,video}){
    if(video != null){
    if(video.status == "activo"){
        return (
            <section className="videoService" style={{backgroundImage:"url('"+url+video.img+"')"}}>
                <div className="content flex">
                <video muted loop playsInline autoPlay id="videoHero" style={{display:"none"}}>
                    <source src={url+video.video} type="video/mp4"/>
                    Your browser does not support the video tag.
                </video>
                <iframe src={"https://www.youtube.com/embed/"+video.video} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
            </section>
        )
    }else{
        return (
            <section>
            </section>
        )
    }
    }
}

export default Video