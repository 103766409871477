import React from 'react'

let vector = [0]

const Nav = function nav ({change}){
    return (
        <div className="navProducts ">
            <div className="container flex">
                <a className="selected selected_1 selecteds" 
                    onClick={()=>change('prodGallery','selected_1')}
                >
                    Overview
                </a>
                <a className="selected_2 selecteds" 
                    onClick={()=>change('product','selected_2')}
                >Specifications</a>
                <a className="selected_3 selecteds" 
                    onClick={()=>change('benefitsProd','selected_3')}
                >Benefits & Features</a>
                <a className="selected_4 selecteds"  
                    onClick={()=>change('prodDocs','selected_4')}
                >Documents & Links</a>
                <a className="selected_5 selecteds" 
                    onClick={()=>change('v360','selected_5')}
                >View 360°</a>
            </div>
        </div>
    )
}

export default Nav