import React from 'react'

let vector = [0]

const Menu = function menu ({products,services}){
    return (
        <div className="contMob colflex" id="nav-menu">
            <div className="flex title">
                <a href="" className="flex">
                    <img src="/img/logo2.svg" alt="logo-img"/>
                </a>
                <div className="actions flex">
                    <a href="#loginModal" rel="modal:open" className="openLoginModal flex" style={{display:"none"}}>
                        <img src="/img/icons/user.svg" alt="login"/>
                    </a>
                    <a href={'/acceder'} className="flex">
                        <img src="/img/icons/user.svg" alt="login"/>
                    </a>
                    <img src="/img/icons/close.svg" alt="close" id="nav-close"/>
                </div>
            </div>

            <div className="content colflex">
                <div className="accTittle flex">
                    <p>Productos</p>
                    <svg className="rotater" width="13" height="8" viewBox="0 0 13 8" fill="none">
                        <path d="M1 1L6.5 6.5L12 1" stroke="white" strokeWidth="2"/>
                    </svg>
                </div>
                <div className="accContent">
                    {products.map((l)=>{
                        if(l.id==1){
                            return(
                                <div key={l.id}>
                                    <a href={'/productos/mineria/de-superficie'}>Minería de superficie</a>
                                    <br/>
                                    <br/>
                                    <a href={'/productos/mineria/subterranea'}>Minería subterránea</a>
                                </div>
                            )
                        }else{
                            return(
                                <a href={'/productos/'+l.slug} key={l.id}>{l.title}</a>
                            )
                        }
                    })}
                </div>
                <div className="accTittle flex">
                    <p>Servicios</p>
                    <svg className="rotater" width="13" height="8" viewBox="0 0 13 8" fill="none">
                        <path d="M1 1L6.5 6.5L12 1" stroke="white" strokeWidth="2"/>
                    </svg>
                </div>
                <div className="accContent">
                    {services.map((l)=>{
                        return(
                            <a href={l.url} key={l.id}>{l.title}</a>
                        )
                    })}
                </div>
                <div className="accTittle flex">
                    <p>Nosotros</p>
                    <svg className="rotater" width="13" height="8" viewBox="0 0 13 8" fill="none">
                        <path d="M1 1L6.5 6.5L12 1" stroke="white" strokeWidth="2"/>
                    </svg>
                </div>
                <div className="accContent">
                    <a href={"/somos-relianz"}>Somos RELIANZ</a>
                    <a className="openFormMore">Contáctanos</a>
                    <a href={"/lo-ultimo"}>Lo último</a>
                    <a href={"/trabaje-con-nosotros"}>Trabaje con nosotros</a>
                    <a href={"/etica-cumplimiento"}>Ética y cumplimiento</a>
                    <a href={"/solicitudes-y-denuncias"}>Denuncias: Línea ética</a>
                    <a href={"/autorizacion-tratamiendo-de-datos-personales"}>Tratamiento de datos personale</a>
                </div>

                <div className="accTittle flex">
                    <p>Esg</p>
                    <svg className="rotater" width="13" height="8" viewBox="0 0 13 8" fill="none">
                        <path d="M1 1L6.5 6.5L12 1" stroke="white" stroke-width="2"/>
                    </svg>
                </div>
                <div className="accContent">
                    <a href={"/sostenibilidad"}>Sostenibilidad</a>
                    <a className="dnone">Equidad e inclusión</a>
                </div>
            </div>

            <div className="language flex">
                <a className="flex">
                    <img src="/img/icons/eng.svg" alt="flag-icon"/>
                    <span>EN</span>
                </a>
            </div>

        </div>
    )
}

export default Menu