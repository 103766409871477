import React from 'react'

let vector = [0]

const Content = function content ({url,trainer,list}){
    return (

        <section className="inTrainer">
            <div className="container colflex">
                <div className="backTrainers flex">
                    <a href={"/soluciones-de-aprendizaje"} className="flex">
                        <img src="/img/icons/backblog.svg" alt="⬅️"/>
                        <span>Atrás</span>
                    </a>
                </div>
                
                <div className="infoTrainer colflex">
                    <picture>
                        <source media="(min-width:1024px)" srcSet={url+trainer.img}/>
                        <img src={url+trainer.img} alt="round-photo"/>
                    </picture>
                    <h2>{trainer.title}</h2>
                    <p>{trainer.text}</p>
                    <span className="moreText" id="moreText">Leer más</span>
                </div>

                <div className="listCourses colflex">
                    {list.map((l)=>{
                        return(
                            <div className="item colflex" key={l.id}>
                                <div className="title flex">
                                    <h3>{l.title}</h3>
                                    <img src="/img/grey.svg" alt="image-course" className="dnone"/>
                                </div>
    
                                <div className="info relative">
                                    <a href={'/cursos/'+l.id}>
                                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" className="accTittle" >
                                    <circle cx="20" cy="20" r="20" fill="#FFCC00"/>
                                    <path d="M19.2929 27.7071C19.6834 28.0976 20.3166 28.0976 20.7071 27.7071L27.0711 21.3431C27.4616 20.9526 27.4616 20.3195 27.0711 19.9289C26.6805 19.5384 26.0474 19.5384 25.6569 19.9289L20 25.5858L14.3431 19.9289C13.9526 19.5384 13.3195 19.5384 12.9289 19.9289C12.5384 20.3195 12.5384 20.9526 12.9289 21.3431L19.2929 27.7071ZM19 13L19 27L21 27L21 13L19 13Z" fill="black"/>
                                    </svg>
                                    </a>
                                    <div className="accContent">
                                        <p>{l.text}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>

            </div>
        </section>
    )
}

export default Content