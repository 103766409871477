import React from 'react'

let vector = [0]
let validate = 1

const Products = function products ({url,text,list}){
    return (
        <section className="productsHome" style={{backgroundImage: "url("+url+text.img+")"}}>
            <div className="container colflex">
                <h1><span>{text.title} </span>{text.subtitle}</h1>
                <p dangerouslySetInnerHTML={{__html: text.text}}></p>
                <div className="itemList colflex">
                    {list.map((l)=>{
                        return(
                            <div className="item flex" key={l.id}>
                                <img src={url+l.img} alt=""/>
                                <a href={'/productos/'+l.slug} className="colflex">
                                    <span>{l.title}</span>
                                    <svg width="33" height="16" viewBox="0 0 33 16" fill="none">
                                        <path d="M32.5757 8.70711C32.9663 8.31659 32.9663 7.68342 32.5757 7.2929L26.2118 0.928934C25.8213 0.53841 25.1881 0.53841 24.7976 0.928934C24.407 1.31946 24.407 1.95262 24.7976 2.34315L30.4544 8L24.7976 13.6569C24.407 14.0474 24.407 14.6805 24.7976 15.0711C25.1881 15.4616 25.8213 15.4616 26.2118 15.0711L32.5757 8.70711ZM-8.74228e-08 9L31.8686 9L31.8686 7L8.74228e-08 7L-8.74228e-08 9Z" fill="white"/>
                                    </svg>
                                </a>
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}

export default Products