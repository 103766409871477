import React from 'react'

let vector = [0]
let validate = 1
let count = 0

const List = function list ({list}){
    return (

        <section className="services3">
            <div className="content colflex">
                <h2>SERVICIOS</h2>
                <div className="slickServices2" id="slickServices2">
                    {list.map((l)=>{
                        count++
                        return(
                            <div className="item" key={l.id}>
                                <div className="title flex">
                                    <p>{l.title}</p>
                                    <div className="badge relative">
                                        <span >{count}</span>
                                    </div>
                                </div>
                                <p dangerouslySetInnerHTML={{__html: l.text}}></p>
                            </div>
                        )
                    })}
                </div>
                {vector.map((v)=>{
                    if(list.length > 0 && validate == 1){
                    //if(validate == 1){
                        validate = 2
                        setTimeout(()=>{
                            $('#slickServices2').slick({
                                slidesToShow: 1,
                                arrows: false,
                                mobileFirst: true,
                                centerMode: true,
                                variableWidth: true,
                                responsive: [
                                    {
                                    breakpoint: 700,        
                                    settings: {
                                        centerMode: false
                                        }
                                    },
                                    {
                                    breakpoint: 1000,
                                        settings: 'unslick'
                                    },
                                ]
                            });
                        },10)
                    }
                })}
            </div>
        </section>
    )
}

export default List