import React from 'react'

let vector = [0]
let validate = 1

const Content = function content ({url,info,list}){
    let date = [""]
    let urlPattern = ""
    let htmlContent = ""
    if(info.created_at != undefined){
        date = info.created_at.split("T")
        urlPattern = /(\b(https?|ftp):\/\/[^\s/$.?#].[^\s]*)/gi;
        htmlContent = info.text.replace(urlPattern, '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>');
    }
    return (
        <section className="contentPost">

            <div className="backPost flex">
                <a href={"/lo-ultimo"} className="flex">
                    <img src="/img/icons/backblog.svg" alt="⬅️"/>
                    <span>Atrás</span>
                </a>
            </div>

            <div className="contentSection colflex">

                <div className="content colflex">
                    <img src={url+info.img} alt="image-post" className="wallpost"/>
                    <h3>{info.title}.</h3>
                    <div className="date flex">
                        <img src="/img/icons/calendar.svg" alt="calendar-icon"/>
                        <span>{date[0]}</span>
                    </div>
                    <div className="textPost" dangerouslySetInnerHTML={{__html: htmlContent}}>
                    </div>
                </div>

                <aside>
                    <div className="content colflex">
                        <h3>Te pueden interesar:</h3>
                        <div className="interestPost" id="interestPosts">
                            {list.map((l)=>{
                                let date_2 = l.created_at.split("T")
                                return(
                                    <div className="item colflex" key={l.id}>
                                        <a href={"/lo-ultimo/"+l.slug} className="thumb flex">
                                            <img src={url+l.img} alt="post-img"/>
                                        </a>
                                        <div className="text colflex">
                                            <a href={"/lo-ultimo/"+l.slug}>{l.title}</a>
                                            <span>{date_2[0]}</span>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        {vector.map((v)=>{
                            if(list.length > 0 && validate == 1){
                                validate = 2
                                setTimeout(()=>{
                                    $('#interestPosts').slick({
                                        slidesToShow: 1,
                                        arrows: false,
                                        mobileFirst: true,
                                        centerMode: true,
                                        // variableWidth: true,
                                        responsive: [
                                          {
                                            breakpoint: 760,
                                            settings: {
                                              slidesToShow: 2,
                                            }
                                          },
                                          {
                                            breakpoint: 1020,
                                            settings: 'unslick'
                                          },
                                        ]
                                    });
                                },10)
                            }
                        })}
                    </div>
                </aside>

            </div>
        </section>
    )
}

export default Content