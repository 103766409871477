import React from 'react'

let vector = [0]

const Sustain = function sustain ({url,text}){
    return (
        <div>
        <img src={url+text.img} className="bannerMid" alt="banner-mid"/>

        <section className="sustainHome" style={{backgroundImage: "url("+url+text.img+")"}}>
            <div className="container colflex">
                <h2>{text.title}</h2>
                <h2>{text.subtitle}</h2>
                <p dangerouslySetInnerHTML={{__html: text.text}}></p>
                <a href={"/sostenibilidad"} className="flex">
                    <span>Saber más...</span>
                    <img src="/img/icons/rightw.svg" alt="➡️"/>
                </a>
            </div>
        </section>
        </div>
    )
}

export default Sustain