import React from 'react'

let vector = [0]

const Product = function product ({list}){
    return (
        <section className="product">
            <div className="container ">
                <div className="specs colflex">
                    
                {Object.keys(list).map((v) => {
                    return (
                        <div className="item" key={v}>
                            <h2>{v}</h2>
                            <table>
                                <tbody>
                                    {list[v].map((vr, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{vr.name}</td>
                                                <td>{vr.textValue == null ? `${vr.metricValue} ${vr.metricUnit}` : vr.textValue}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    );
                })}

                </div>
            </div>
        </section>
    )
}

export default Product