import React from 'react'

let vector = [0]

const Content = function content ({text}){
    return (
        <section className="terms">
            <div className="container colflex">
                <h1><span>{text.title}</span>{text.subtitle}</h1>
                <p dangerouslySetInnerHTML={{__html: text.text}}></p>
            </div>
        </section>
    )
}

export default Content