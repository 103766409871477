import React from 'react'

let vector = [0]

const Content = function content ({url,lines,lines_one,list}){
    return (
        <section className="courses">
            <div className="container colflex">
                <a href={"/productos/repuestos"} className="backTrain flex">
                    <img src="/img/icons/backblog.svg" alt="⬅️"/>
                    <span>Atrás</span>
                </a>

                <div className="filters">
                    <select >
                        {lines.map((c)=>{
                            return(
                                <option key={c.id} value={c.id}>
                                    {c.title}
                                </option>
                            )
                        })}
                    </select>
                </div>

                <div className="filtersBig dnone">
                    {lines.map((c)=>{
                        return(
                        <a 
                            key={c.id} 
                            href={'/repuestos/'+c.slug} 
                            className={c.id == lines_one.id?"active":""} 
                            id={"links_"+c.id}
                        >
                            {c.title}
                        </a>
                        )
                    })}
                </div>
            
                <div className="blockCourse colflex block_spare_parts">

                    <div className="text flex text_repuestos">
                        <div className='colflex'>
                            <h2>{lines_one.title}</h2>
                            <p dangerouslySetInnerHTML={{__html: lines_one.subtitle+'<br/><br/>'+lines_one.text}}></p>
                        </div>
                        <img src={url+lines_one.img}/>
                    </div>

                    <div className="listItems flex listItems_repuestos">
                        {list.map((l)=>{
                            return(
                                <p key={l.id}>
                                    <strong>{l.title}</strong>
                                    <p dangerouslySetInnerHTML={{__html: l.text}}></p>
                                    {vector.map((v)=>{
                                        if(l.video_id != null){
                                            return(
                                                <iframe key={l.id} src={"https://www.youtube.com/embed/"+l.video_id} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen className='video_parts'></iframe>
                                            )
                                        }
                                    })}
                                </p>
                            )
                        })}
                    </div>

                </div>
                

                {vector.map((v)=>{
                    if(lines_one.url != null){
                        return(
                            <a key={v} href={lines_one.url } target='_blank' className="downTem_repuestos flex">
                                <span>{lines_one.url_title}</span>
                                <img src="/img/icons/downb.svg" alt="⬇️"/>
                            </a>
                        )
                    }
                })}
                    
            </div>
        </section>
    )
}

export default Content