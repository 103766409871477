import { line } from 'laravel-mix/src/Log'
import React from 'react'

let vector = [0]

const Plans = function plans ({url,lines,lines_one,changePlans}){
    return (
        <section className="plans plans_spareparts">
            <div className="container colflex">
                <div className="courses colflex">
                    <h1>Líneas</h1>
                    {lines.map((c)=>{
                        return(
                        <a 
                            key={c.id} 
                            href={"/repuestos/"+lines_one.slug} 
                            className="flex"
                        >
                            {c.title}
                            <img src="/img/icons/rightb2.svg" alt="➡️"/>
                        </a>
                        )
                    })}
                </div>
                <div className="courses2 dnone">
                    <h1>Líneas</h1>
                    <div className="links flex">
                        {lines.map((c)=>{
                            return(
                            <a key={c.id} onClick={()=>changePlans(c.id)} className={c.id == 1?"active":""} id={"links_"+c.id}>
                                {c.title}
                            </a>
                            )
                        })}
                    </div>
                    <div className="content flex">
                        <div className="info colflex">
                            <h3>{lines_one.subtitle}</h3>
                            <p dangerouslySetInnerHTML={{__html: lines_one.text}}></p>
                            <a href={"/repuestos/"+lines_one.slug} className="flex">
                                Saber más
                                <img src="/img/icons/rightw.svg" alt=""/>
                            </a>
                        </div>
                        <img src={url+lines_one.img} alt=""/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Plans