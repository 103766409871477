let https = window.location.host+'/';

if(window.location.pathname.replace(https,'') == "/"){
    require('./view/index');
}
if(window.location.pathname.replace(https,'') == "/somos-relianz"){
    require('./view/about');
}
if(window.location.pathname.replace(https,'') == "/lo-ultimo"){
    require('./view/blog');
}
if(window.location.pathname.replace(https,'').includes("/lo-ultimo/")){
    require('./view/post');
}
if(window.location.pathname.replace(https,'') == "/trabaje-con-nosotros"){
    require('./view/jobs');
}
if(window.location.pathname.replace(https,'').includes("/trabaje-con-nosotros/aplicar/")){
    require('./view/jobs_apply');
}

if(window.location.pathname.replace(https,'') == "/etica-cumplimiento"){
    require('./view/ethics');
}
if(window.location.pathname.replace(https,'') == "/solicitudes-y-denuncias"){
    require('./view/report');
}
if(window.location.pathname.replace(https,'') == "/autorizacion-tratamiendo-de-datos-personales"){
    require('./view/terms');
}

if(window.location.pathname.replace(https,'').includes("/servicios/")){
    require('./view/services');
}
if(window.location.pathname.replace(https,'') == "/laboratorios"){
    require('./view/labs');
}
if(window.location.pathname.replace(https,'') == "/soluciones-de-aprendizaje"){
    require('./view/trainings');
}

if(window.location.pathname.replace(https,'') == "/cursos"){
    require('./view/courses');
}
if(window.location.pathname.replace(https,'').includes("/cursos-info/")){
    require('./view/courses');
}
if(window.location.pathname.replace(https,'').includes("/cursos/")){
    require('./view/intcourse');
}
if(window.location.pathname.replace(https,'').includes("/entrenador/")){
    require('./view/trainer');
}

if(window.location.pathname.replace(https,'') == "/sostenibilidad"){
    require('./view/sustain');
}

if(window.location.pathname.replace(https,'') == "/acceder"){
    require('./view/login');
}
if(window.location.pathname.replace(https,'') == "/archivos"){
    require('./view/files');
}

if(window.location.pathname.replace(https,'') == "/productos/repuestos"){
    require('./view/spare_parts');
}
if(window.location.pathname.replace(https,'').includes("/repuestos/")){
    require('./view/spare_parts_services');
}

if(window.location.pathname.replace(https,'').includes("/productos/mineria")){
    require('./view/products/mineria');
}
if(window.location.pathname.replace(https,'').includes("/productos/categoria")){
    require('./view/products/category');
}
if(window.location.pathname.replace(https,'').includes("/productos/sub-categoria")){
    require('./view/products/subcategory');
}
if(window.location.pathname.replace(https,'').includes("/producto/")){
    require('./view/products/info');
}