import React,{useRef,Component} from 'react';
import { createRoot } from "react-dom/client";

import Header from "../components/header";
import Menu from "../components/menu";
import Footer from "../components/footer";

import Login from "../components/login";
import Float from "../components/float";

import Content from "../components/spare_parts_services/content";
import Gallery from "../components/spare_parts_services/gallery";

const rootElement = document.getElementById("content_spare_parts_services");
const root = createRoot(rootElement);

export default class Services extends Component {
    constructor(props){
        super(props)

        this.state = {
            name: 'spare_parts_services',
            url_admin:[],
            info:[],
            products:[],
            services:[],
            config_form:[],
            error: null,
            form_float:{
                name: '',
                mail: '',
                phone: '',
                city: '',
                company: '',
                messague: '',
                token:'',
                check_terms: 'no',
                page: '',
                send_mail: '',
            },
            lines:[],
            lines_one:[],
            list:[],
            gallery:[],
        }

        this._recaptchaRef = React.createRef()
        this.handleChangeFormFloat = this.handleChangeFormFloat.bind(this)
        this.formSubmitFloat = this.formSubmitFloat.bind(this)
        this.submitClickFloat = this.submitClickFloat.bind(this)
        this.termsFloat = this.termsFloat.bind(this)

        this.changePlans = this.changePlans.bind(this)
    }

    async componentDidMount(){
        try{
            let res = await fetch('/get/spare_parts_id/'+spare_parts_id)
            let data = await res.json()
            this.setState({
                url_admin:data[0],
                info:data[1],
                products:data[2],
                services:data[3],
                config_form:data[4],
                lines:data[5],
                lines_one:data[6],
                list:data[7],
                gallery:data[8],
                form_float:{
                    ...this.state.form_float,
                    page: data[4].page,
                    send_mail: data[4].mail
                },
            })
        }catch (error) {
            this.setState({
                error
            })
        }
    }

    handleChangeFormFloat(e){
        this.setState({
            form_float:{
                ...this.state.form_float,
                [e.target.name]:e.target.value
            }
        })
    }

    formSubmitFloat = async (e) => {
        if(
            this.state.form_float.name == '' ||
            this.state.form_float.mail == '' ||
            this.state.form_float.phone == '' ||
            this.state.form_float.city == '' ||
            this.state.form_float.company == '' 
        ){
            swal({
                text: "Debes digitar los datos necesarios",
                icon: imageURL,
                button: "OK!",
            });
            return false;
        }

        if(this.state.form_float.check_terms == 'no'){
            swal({
                text: "Debes aceptar los terminos y condiciones",
                icon: imageURL,
                button: "OK!",
            });
            return false;
        }

        swal({
            text: "Espere un momento",
            icon: imageURL,
            button: false,
            closeOnClickOutside: false,
        });

        if(this.state.form_float.token == ""){
            const token = await this._recaptchaRef.current.executeAsync();
            this.setState({
                form_float:{
                    ...this.state.form_float,
                    token: token
                }
            });
            if(!token){
                swal({
                    text: "Error, eres un robot",
                    icon: "error",
                    button: "OK!",
                    closeOnClickOutside: false,
                });
                return false;
            }
        }

        try{
            let config = {
                method: 'POST',
                headers:{
                    'Accept':'application/json',
                    'Content-Type':'application/json'
                },
                body: JSON.stringify(this.state.form_float)
            }
            let res = await fetch(`/api/contact-float`,config)
            let data = await res.json()
            if(data == 1){
                this.setState({
                    form_float:{
                        name: '',
                        mail: '',
                        phone: '',
                        city: '',
                        company: '',
                        messague: '',
                        token:'',
                        check_terms: 'no',
                        page: '',
                        send_mail: '',
                    }
                })
                document.getElementById("form_float").reset();
                swal.close();
                swal({
                    text: "Gracias por contactarnos",
                    icon: "success",
                    button: "OK!",
                    dangerMode: false,
                    closeOnClickOutside: false,
                })
                    .then((willDelete) => {
                        if (willDelete) {
                            location.reload();
                        } else {

                        }
                    });
            }else{
                console.log(data)
                alert('ocurrio un error');
                setTimeout(function (){
                    //location.reload();
                },1000)
            }
        }catch (error) {
            this.setState({
                error
            })
        }
    }

    submitClickFloat(){
        this.formSubmitFloat()
    }

    termsFloat(){
        let value = ""
        if(this.state.form_float.check_terms == "no"){
            $('#check_terms_float').prop('checked',true)
            value = "si"
        }else{
            $('#check_terms_float').prop('checked',false)
            value = "no"
        }
        this.setState({
            form_float:{
                ...this.state.form_float,
                check_terms:value
            }
        })
    }

    changePlans(id){
        $('.filtersBig a').removeClass('active')
        $('#links_'+id).addClass('active')
        const update = this.state.list_all.filter(
            (c) => id === c.trainings_category_id,
        );
        console.log(update)
        const update_2 = this.state.category.filter(
            (c) => id === c.id,
        );
        this.setState({
            courses:update,
            info_category:update_2[0]
        })
    }

    render() {
        return (
            <div>
                <Header
                    products={this.state.products}
                    services={this.state.services}
                />
                <Menu
                    products={this.state.products}
                    services={this.state.services}
                />
                <Login
                />
                <main>
                    <Content
                        url={this.state.url_admin}
                        lines={this.state.lines}
                        lines_one={this.state.lines_one}
                        list={this.state.list}
                    />
                    <Gallery
                        text={this.state.lines_one}
                        url={this.state.url_admin}
                        list={this.state.gallery}
                    />
                </main>
                <Float
                    config_form={this.state.config_form}

                    onChange={this.handleChangeFormFloat}
                    form={this.state.form_float}
                    submit={this.submitClickFloat}
                    recaptchaRef={this._recaptchaRef}
                    terms={this.termsFloat}
                />
                <Footer
                    products={this.state.products}
                    services={this.state.services}
                    info={this.state.info}
                />
            </div>
        );
    }

}

if (document.getElementById('content_spare_parts_services')) {
    root.render(
        <Services />
    )
}