import React from 'react'

let vector = [0]

const Footer = function footer ({products,services,info}){
    return (
        <footer className="relative" style={{backgroundImage: "url('/img/backfoo.avif')"}}>
            <img src="/img/up.svg" alt="⬆️" className="absolute up" id="upBtn"/>
            <div className="container colflex">
                <img src="/img/logo2.svg" alt="logo" className="logoFoo aniBt"/>
                <div className="menuFooMob colflex">
                    <div className="accTittle flex">
                        <h3>Nosotros</h3>
                        <img src="/img/icons/down.svg" alt="⬇️"/>
                    </div>
                    <div className="accContent">
                        <a href={"/somos-relianz"}>Somos Relianz</a>
                        <a className="openFormMore">Contáctanos</a>
                        <a href={"/trabaje-con-nosotros"}>Trabaje con nosotros</a>
                        <a href={"/etica-cumplimiento"}>Ética y cumplimiento</a>
                        <a href={"/solicitudes-y-denuncias"}>Denuncias: Línea ética</a>
                        <a href={"/autorizacion-tratamiendo-de-datos-personales"}>Tratamiento de datos personales</a>
                        <a href={"/lo-ultimo"}>Lo ultimo</a>
                    </div>
                    <div className="accTittle flex">
                        <h3>productos</h3>
                        <img src="/img/icons/down.svg" alt="⬇️"/>
                    </div>
                    <div className="accContent">
                        {products.map((l)=>{
                            if(l.id==1){
                                return(
                                    <div key={l.id}>
                                        <a href={'/productos/mineria/de-superficie'}>Minería de superficie</a>
                                        <br/>
                                        <br/>
                                        <a href={'/productos/mineria/subterranea'}>Minería subterránea</a>
                                    </div>
                                )
                            }else{
                                return(
                                    <a hhref={'/productos/'+l.slug} key={l.id}>{l.title}</a>
                                )
                            }
                        })}
                    </div>
                    <div className="accTittle flex">
                        <h3>servicios</h3>
                        <img src="/img/icons/down.svg" alt="⬇️"/>
                    </div>
                    <div className="accContent">
                        {services.map((l)=>{
                            return(
                                <a href={l.url} key={l.id}>{l.title}</a>
                            )
                        })}
                    </div>

                    <div className="accTittle flex">
                        <h3>esg</h3>
                        <img src="/img/icons/down.svg" alt="⬇️"/>
                    </div>
                    <div className="accContent">
                        <a href={'/sostenibilidad'}>Sostenibilidad</a>
                        <a className="dnone">Equidad e inclusión</a>
                    </div>

                    <div className="socialMob colflex">
                        <h3>SÍGUENOS</h3>
                        <div className="social flex">
                            <a href={info.facebook_url} target='_blank' className="flex"><img src="/img/icons/fbfoo2.svg" alt="fb-icon"/></a>
                            <a href={info.instagram_url} target='_blank' className="flex"><img src="/img/icons/igfoo2.svg" alt="ig-icon"/></a>
                            <a href={info.linkedin_url} target='_blank' className="flex"><img src="/img/icons/linfoo2.svg" alt="lin-icon"/></a>
                            <a href={info.youtube_url} target='_blank' className="flex"><img src="/img/icons/ytfoo.svg" alt="lin-icon"/></a>
                        </div>
                    </div>
                </div>
                <div className="item dnone">
                    <h3>NOSOTROS</h3>
                    <a href={"/somos-relianz"}>Somos Relianz</a>
                    <a className="openFormMore">Contáctanos</a>
                    <a href={"/trabaje-con-nosotros"}>Trabaje con nosotros</a>
                    <a href={"/etica-cumplimiento"}>Ética y cumplimiento</a>
                    <a href={"/solicitudes-y-denuncias"}>Denuncias: Línea ética</a>
                    <a href={"/autorizacion-tratamiendo-de-datos-personales"}>Tratamiendo de datos personales</a>
                    <a href={"/lo-ultimo"}>Lo último</a>
                </div>
                <div className="item dnone">
                    <h3>PRODUCTOS</h3>
                    {products.map((l)=>{
                        if(l.id==1){
                            return(
                                <div key={l.id}>
                                    <a href={'/productos/mineria/de-superficie'}>Minería de superficie</a>
                                    <br/>
                                    <br/>
                                    <a href={'/productos/mineria/subterranea'}>Minería subterránea</a>
                                </div>
                            )
                        }else{
                            return(
                                <a href={'/productos/'+l.slug} key={l.id}>{l.title}</a>
                            )
                        }
                    })}
                </div>
                <div className="item dnone">
                    <h3>SERVICIOS</h3>
                    {services.map((l)=>{
                        return(
                            <a href={l.url} key={l.id}>{l.title}</a>
                        )
                    })}
                </div>
                <div className="item dnone">
                    <h3 className='green'>ESG</h3>
                    <a href={"/sostenibilidad"}>Sostenibilidad</a>
                    <a className="dnone">Equidad e inclusión</a>
                    <h3>SÍGUENOS</h3>
                    <div className="social flex">
                        <a href={info.facebook_url} target='_blank'  className="flex"><img src="/img/icons/fbfoo2.svg" alt="fb-icon"/></a>
                        <a href={info.instagram_url} target='_blank'  className="flex"><img src="/img/icons/igfoo2.svg" alt="ig-icon"/></a>
                        <a href={info.linkedin_url} target='_blank' className="flex"><img src="/img/icons/linfoo2.svg" alt="lin-icon"/></a>
                        <a href={info.youtube_url} target='_blank'  className="flex"><img src="/img/icons/ytfoo.svg" alt="lin-icon"/></a>
                    </div>
                    <img src="/img/logo2.svg" alt="logo" className="logoAlt"/>
                </div>
            </div>
            <div className="flag flex">
                <div className="am"></div>
                <div className="az"></div>
                <div className="ro"></div>
            </div>
            <div className="gtnd colflex">
                <p>RELIANZ.</p>
                <p>Todos los derechos reservados</p>
                <p className="marker">|</p>
                <p>Creado por</p>
                <a href="https://gutand.com/" target="_blank" className="flex"><img src="/img/icons/gtnd.svg" alt="gutand-icon"/></a>
            </div>
        </footer>
    )
}

export default Footer