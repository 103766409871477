import React from 'react'

let vector = [0]

const Mission = function mission ({url,text,text_2,text_3}){
    return (
        <section className="mission">
            <div className="container colflex">
                <div className="item colflex">
                    <img src={url+text.img} alt="image-mission"/>
                    <div className="text">
                        <h2>{text.title}</h2>
                        <p dangerouslySetInnerHTML={{__html: text.text}}></p>
                    </div>
                </div>
                <div className="item colflex">
                    <img src={url+text_2.img} alt="image-mission"/>
                    <div className="text">
                        <h2>{text_2.title}</h2>
                        <p dangerouslySetInnerHTML={{__html: text_2.text}}></p>
                    </div>
                </div>
                <div className="item colflex">
                    <img src={url+text_3.img} alt="image-mission"/>
                    <div className="text">
                        <h2>{text_3.title}</h2>
                        <p dangerouslySetInnerHTML={{__html: text_3.text}}></p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Mission