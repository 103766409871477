import React from 'react'
import ReCAPTCHA from "react-google-recaptcha";

let vector = [0]

const Contact = function contact ({
    url,text,
    onChange,form,submit,recaptchaRef,terms
    }){
    return (
        <section className="contactHome" style={{backgroundImage: "url("+url+text.img+")"}}>
            <div className="content">
                <h2>{text.title}</h2>
                <p>{text.subtitle}</p>
                <form className="colflex" id="formContact">
                    <input 
                        type="text" 
                        placeholder="Nombre"
                        name="name"
                        value={form.name}
                        onChange={onChange}
                    />
                    <input 
                        type="tel" 
                        placeholder="Teléfono"
                        name="phone"
                        value={form.phone}
                        onChange={onChange}
                    />
                    <input 
                        type="email" 
                        placeholder="Correo Electronico"
                        name="mail"
                        value={form.mail}
                        onChange={onChange}
                    />
                    <textarea 
                        rows="6"
                        placeholder="Mensaje (Opcional)"
                        name="messague"
                        value={form.messague}
                        onChange={onChange}
                    ></textarea>
                    <div className="policy colflex">
                        <div className="text grid">
                            <input
                                id="check_terms"
                                type="checkbox"
                                onClick={()=>terms()}
                            />
                            <span>{text.text}
                            <a href={'/autorizacion-tratamiendo-de-datos-personales'} target="_blank" >Ver Autorización Tratamiento De Datos Personales.
                            </a>
                            </span>
                        </div>
                        <a onClick={()=>submit()} className="btnFormHome">
                            ENVIAR
                        </a>
                    </div>
                </form>
            </div>
        </section>
    )
}

export default Contact