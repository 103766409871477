import React from 'react'
import ReCAPTCHA from "react-google-recaptcha";

let vector = [0]

const Float = function float ({
    config_form,
    onChange,form,submit,recaptchaRef,terms
    }){
    return (
        <div>
            <div className="floats colflex">
                <a className=" relative openFormMore">
                    <img src="/img/icons/envelope.svg" alt="📧"/>
                </a>
                <a href="" className="relative">
                  <img src="/img/icons/chat.svg" alt="📧"/>
                </a>
            </div>

            <a className="hidden flat relative openFormMore" id="flat">
                <span>Contactar</span>
            </a>

            <div className="formFloat close" id="formFloat">
                <div className="black" id="black"></div>
                <div className="content colflex relative">
                    <img src="/img/icons/close.svg" alt="close" id="form-close" className="formClose"/>
                    <h2>{config_form.title}</h2>
                    <p>{config_form.text}</p>
                    <form className="colflex" id="form_float">
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            size="invisible"
                            sitekey="6LeAu3cgAAAAAIhj6gFouDEtkkynaMvDRUtSJGM1"
                        />
                        <input 
                            type="text" 
                            placeholder="Nombre"
                            name="name"
                            value={form.name}
                            onChange={onChange}
                        />
                        <input 
                            type="email" 
                            placeholder="Correo Electronico"
                            name="mail"
                            value={form.mail}
                            onChange={onChange}
                        />
                        <input 
                            type="tel" 
                            placeholder="Teléfono"
                            name="phone"
                            value={form.phone}
                            onChange={onChange}
                        />
                        <input 
                            type="text" 
                            placeholder="Ciudad"
                            name="city"
                            value={form.city}
                            onChange={onChange}
                        />
                        <input 
                            type="text" 
                            placeholder="Empresa"
                            name="company"
                            value={form.company}
                            onChange={onChange}
                        />
                        <textarea 
                            rows="6"
                            placeholder="Mensaje (Opcional)"
                            name="messague"
                            value={form.messague}
                            onChange={onChange}
                        ></textarea>
                        <div className="policy colflex">
                            <div className="text grid">
                                <input
                                    id="check_terms_float"
                                    type="checkbox"
                                    onClick={()=>terms()}
                                />
                                <span>Al marcar la casilla, se entiende que ha leído, aceptado y autorizado el Tratamiento de sus Datos Personales de acuerdo con nuestra Política de Tratamiento de Información. <a href={"/autorizacion-tratamiendo-de-datos-personales"} target='_blank'>Ver Autorización Tratamiento De Datos Personales.</a></span>
                            </div>
                            <a onClick={()=>submit()} className="btnFormModal">
                                ENVIAR
                            </a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Float