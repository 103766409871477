import React from 'react'

let vector = [0]
let validate = 1

const Banner = function banner ({url,list}){
    return (
        <section className="bannerHome relative">
            <img src="/img/back.svg" alt="⬅️" className="dnone backBanner absolute"/>
            <img src="/img/next.svg" alt="➡️" className="dnone nextBanner absolute"/>
            <div className="sliderBanner" id="bannerHome">
                {list.map((l)=>{
                    if(l.url != null){
                    return(
                        <div className="item relative" key={l.id}>
                            <a href={l.url}> 
                                <picture className="flex">
                                    <source media="(min-width:450px)" srcSet={url+l.img}/>
                                    <img src={url+l.img_mobile} alt="imageBanner"/>
                                </picture>
                                <div className="backText absolute colflex">
                                    <h3>{l.text}</h3>
                                    <a>
                                        Saber más
                                    </a>
                                </div>
                            </a>
                        </div>
                    )
                    }else{
                        return(
                            <div className="item relative" key={l.id}>
                                <a> 
                                    <picture className="flex">
                                        <source media="(min-width:450px)" srcSet={url+l.img}/>
                                        <img src={url+l.img_mobile} alt="imageBanner"/>
                                    </picture>
                                    <div className="backText absolute colflex">
                                        <h3>{l.text}</h3>
                                    </div>
                                </a>
                            </div>
                        )
                    }
                })}
            </div>
            {vector.map((v)=>{
                if(list.length > 0 && validate == 1){
                    setTimeout(()=>{
                        $('#bannerHome').slick({
                            slidesToShow: 1,
                            arrows: false,
                            dots: true,
                            mobileFirst: true,
                            autoplay: true,
                            autoplaySpeed: 8000,
                            responsive: [
                                {
                                breakpoint: 1250,
                                    settings: {
                                    arrows: true,
                                    prevArrow: '.backBanner',
                                    nextArrow: '.nextBanner',
                                    dots: true,
                                    }
                                },
                            ]
                        });
                    },10)
                }
            })}
        </section>
    )
}

export default Banner