import React from 'react'

let vector = [0]

const Banner = function banner ({url,text}){
    return (
        <section className="bannerAbout">
            <div className="content colflex">
                <iframe src={"https://www.youtube.com/embed/"+text.video} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <div className="info colflex">
                    <h1 className="aniLtr">{text.title}</h1>
                    <p dangerouslySetInnerHTML={{__html: text.text}}></p>
                </div>
            </div>
        </section>
    )
}

export default Banner