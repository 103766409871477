import React from 'react'

let vector = [0]

const History = function history ({url,text}){
    return (
        <section className="history">
            <div className="container colflex">
                <img src={url+text.img} alt="map-img"/>
                <div className="text colflex aniBt">
                    <h2 clas><span>{text.title} </span>{text.subtitle}</h2>
                    <p dangerouslySetInnerHTML={{__html: text.text}}></p>
                </div>
            </div>
        </section>
    )
}

export default History