import React from 'react'

let vector = [0]

const Plans = function plans ({url,text,category,category_info,changePlans}){
    return (
        <section className="plans">
            <div className="container colflex">
                <div className="text">
                    <h1>{text.title}</h1>
                    <p dangerouslySetInnerHTML={{__html: text.text}}></p>
                </div>
                <div className="courses colflex">
                    <h1>Cursos</h1>
                    {category.map((c)=>{
                        return(
                        <a key={c.id} href={"/cursos-info/"+c.id} className="flex">
                            {c.title}
                            <img src="/img/icons/rightb2.svg" alt="➡️"/>
                        </a>
                        )
                    })}
                </div>
                <div className="courses2 dnone">
                    <div className="links flex">
                        {category.map((c)=>{
                            return(
                            <a key={c.id} onClick={()=>changePlans(c.id)} className={c.id == 1?"active":""} id={"links_"+c.id}>
                                {c.title}
                            </a>
                            )
                        })}
                    </div>
                    <div className="content flex">
                        <div className="info colflex">
                            <h3>{category_info.title}</h3>
                            <p dangerouslySetInnerHTML={{__html: category_info.text}}></p>
                            <a href={"/cursos-info/"+category_info.id} className="flex">Ver cursos<img src="/img/icons/rightw.svg" alt=""/></a>
                        </div>
                        <img src={url+category_info.img} alt=""/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Plans