import React,{useRef,Component} from 'react';
import { createRoot } from "react-dom/client";

import Header from "../components/header";
import Menu from "../components/menu";
import Footer from "../components/footer";

import Float from "../components/float";

import Content from "../components/login/content";

const rootElement = document.getElementById("content_login");
const root = createRoot(rootElement);

export default class Login extends Component {
    constructor(props){
        super(props)

        this.state = {
            name: 'login',
            url_admin:[],
            info:[],
            products:[],
            services:[],
            config_form:[],
            error: null,
            text: [],
            form_float:{
                name: '',
                mail: '',
                phone: '',
                city: '',
                company: '',
                messague: '',
                token:'',
                check_terms: 'no',
                page: '',
                send_mail: '',
            },
            formLogin:{
                user:'',
                password:'',
                token:'',
            },
        }

        this._recaptchaRef = React.createRef()
        this.handleChangeFormFloat = this.handleChangeFormFloat.bind(this)
        this.formSubmitFloat = this.formSubmitFloat.bind(this)
        this.submitClickFloat = this.submitClickFloat.bind(this)
        this.termsFloat = this.termsFloat.bind(this)


        this.handleChangeFormLogin = this.handleChangeFormLogin.bind(this)
        this.formLoginSubmit = this.formLoginSubmit.bind(this)
        this.submitClick = this.submitClick.bind(this)
    }

    async componentDidMount(){
        try{
            let res = await fetch('/get/home')
            let data = await res.json()

            this.setState({
                url_admin:data[0],
                info:data[1],
                products:data[2],
                services:data[3],
                config_form:data[4],
                form_float:{
                    ...this.state.form_float,
                    page: data[4].page,
                    send_mail: data[4].mail
                }
            })
        }catch (error) {
            this.setState({
                error
            })
        }
    }


    handleChangeFormFloat(e){
        this.setState({
            form_float:{
                ...this.state.form_float,
                [e.target.name]:e.target.value
            }
        })
    }

    formSubmitFloat = async (e) => {
        if(
            this.state.form_float.name == '' ||
            this.state.form_float.mail == '' ||
            this.state.form_float.phone == '' ||
            this.state.form_float.city == '' ||
            this.state.form_float.company == '' 
        ){
            swal({
                text: "Debes digitar los datos necesarios",
                icon: imageURL,
                button: "OK!",
            });
            return false;
        }

        if(this.state.form_float.check_terms == 'no'){
            swal({
                text: "Debes aceptar los terminos y condiciones",
                icon: imageURL,
                button: "OK!",
            });
            return false;
        }

        swal({
            text: "Espere un momento",
            icon: imageURL,
            button: false,
            closeOnClickOutside: false,
        });

        if(this.state.form_float.token == ""){
            const token = await this._recaptchaRef.current.executeAsync();
            this.setState({
                form_float:{
                    ...this.state.form_float,
                    token: token
                }
            });
            if(!token){
                swal({
                    text: "Error, eres un robot",
                    icon: "error",
                    button: "OK!",
                    closeOnClickOutside: false,
                });
                return false;
            }
        }

        try{
            let config = {
                method: 'POST',
                headers:{
                    'Accept':'application/json',
                    'Content-Type':'application/json'
                },
                body: JSON.stringify(this.state.form_float)
            }
            let res = await fetch(`/api/contact-float`,config)
            let data = await res.json()
            if(data == 1){
                this.setState({
                    form_float:{
                        name: '',
                        mail: '',
                        phone: '',
                        city: '',
                        company: '',
                        messague: '',
                        token:'',
                        check_terms: 'no',
                        page: '',
                        send_mail: '',
                    }
                })
                document.getElementById("form_float").reset();
                swal.close();
                swal({
                    text: "Gracias por contactarnos",
                    icon: "success",
                    button: "OK!",
                    dangerMode: false,
                    closeOnClickOutside: false,
                })
                    .then((willDelete) => {
                        if (willDelete) {
                            location.reload();
                        } else {

                        }
                    });
            }else{
                console.log(data)
                alert('ocurrio un error');
                setTimeout(function (){
                    //location.reload();
                },1000)
            }
        }catch (error) {
            this.setState({
                error
            })
        }
    }

    submitClickFloat(){
        this.formSubmitFloat()
    }

    termsFloat(){
        let value = ""
        if(this.state.form_float.check_terms == "no"){
            $('#check_terms_float').prop('checked',true)
            value = "si"
        }else{
            $('#check_terms_float').prop('checked',false)
            value = "no"
        }
        this.setState({
            form_float:{
                ...this.state.form_float,
                check_terms:value
            }
        })
    }

    handleChangeFormLogin(e){
        this.setState({
            formLogin:{
                ...this.state.formLogin,
                [e.target.name]:e.target.value
            }
        })
    }

    formLoginSubmit = async (e) => {
        if(
            this.state.formLogin.user == '' ||
            this.state.formLogin.password == ''
        ){
            swal({
                text: "Debes digitar todos los datos",
                icon: imageURL,
                button: "OK!",
            });
            return false;
        }

        swal({
            text: "Espere un momento",
            icon: imageURL,
            button: false,
            closeOnClickOutside: false,
        });

        try{
            let configLogin = {
                method: 'POST',
                headers:{
                    'Accept':'application/json',
                    'Content-Type':'application/json'
                },
                body: JSON.stringify(this.state.formLogin)
            }
            let resLogin = await fetch(`/api/login`,configLogin)
            let dataLogin = await resLogin.json()
            console.log(dataLogin)
            console.log(dataLogin[0])
            if(dataLogin == 1){
                this.setState({
                    formLogin:{
                        user:'',
                        password:'',
                        token:'',
                    }
                })
                location.href = '/archivos';
            }else if(dataLogin == 2) {
                swal({
                    text: "Error de recaptcha",
                    icon: "warning",
                    button: "OK!",
                });
            }else if(dataLogin == 3) {
                swal({
                    text: "Usuario o contraseña incorrecta",
                    icon: "warning",
                    button: "OK!",
                });
            }else{
                alert('ocurrio un error');
                setTimeout(function (){
                    location.reload();
                },1000)
            }
        }catch (error) {
            this.setState({
                error
            })
        }
    }

    submitClick(){
        this.formLoginSubmit()
    }

    render() {
        return (
            <div>
                <Header
                    products={this.state.products}
                    services={this.state.services}
                />
                <Menu
                    products={this.state.products}
                    services={this.state.services}
                />
                <main>
                    <Content
                        formlogin={this.state.formLogin}
                        onChangeLogin={this.handleChangeFormLogin}
                        recaptchaRefLogin={this._recaptchaRef}
                        onSubmitLogin={this.formLoginSubmit}
                        submitClick={this.submitClick}
                    />
                </main>
                <Float
                    config_form={this.state.config_form}

                    onChange={this.handleChangeFormFloat}
                    form={this.state.form_float}
                    submit={this.submitClickFloat}
                    recaptchaRef={this._recaptchaRef}
                    terms={this.termsFloat}
                />
                <Footer
                    products={this.state.products}
                    services={this.state.services}
                    info={this.state.info}
                />
            </div>
        );
    }

}

if (document.getElementById('content_login')) {
    root.render(
        <Login />
    )
}