import React,{useRef,Component} from 'react';
import { createRoot } from "react-dom/client";

import Header from "../../components/header";
import Menu from "../../components/menu";
import Footer from "../../components/footer";

import Login from "../../components/login";
import Float from "../../components/float";

import Nav from "../../components/product/nav";
import Info from "../../components/product/product";
import Docs from "../../components/product/docs";
import Gallery from "../../components/product/gallery";
import Benefits from "../../components/product/benefits";
import V360 from "../../components/product/v360";

const rootElement = document.getElementById("content_product");
const root = createRoot(rootElement);

//pagination configuration
let ITEMS_FOR_PAGES = 8
let datosFromApi = []
let items = []
let currentPage = 0
let pages = 0

export default class Product extends Component {
    constructor(props){
        super(props)

        this.state = {
            name: 'product',
            url_admin:[],
            info:[],
            products:[],
            services:[],
            config_form:[],
            error: null,
            form_float:{
                name: '',
                mail: '',
                phone: '',
                city: '',
                company: '',
                messague: '',
                token:'',
                check_terms: 'no',
                page: '',
                send_mail: '',
            },
            overview:[],
            images:[],
            specifications:[],
        }

        this._recaptchaRef = React.createRef()
        this.handleChangeFormFloat = this.handleChangeFormFloat.bind(this)
        this.formSubmitFloat = this.formSubmitFloat.bind(this)
        this.submitClickFloat = this.submitClickFloat.bind(this)
        this.termsFloat = this.termsFloat.bind(this)

        this.changeNav = this.changeNav.bind(this)

    }

    async componentDidMount(){
        try{
            let res = await fetch('/get/product_base/')
            let data = await res.json()

            this.setState({
                url_admin:data[0],
                info:data[1],
                products:data[2],
                services:data[3],
                config_form:data[4],
                form_float:{
                    ...this.state.form_float,
                    page: data[4].page,
                    send_mail: data[4].mail
                },
            })


            let res_2 = await fetch('/products/'+product_id_api)
            let data_2= await res_2.json()
            //console.log(data_2)
            const data_spe = data_2.product.product.specifications
            const groupedData = data_spe.reduce((acc, item) => {
                const { headerName, value } = item;
                
                // Si el campo headerName no existe en el acumulador, lo inicializamos con un array vacío
                if (!acc[headerName]) {
                  acc[headerName] = [];
                }
                
                // Agregamos el item al array correspondiente en el acumulador
                acc[headerName].push(item);
              
                return acc;
            }, {});
            console.log(groupedData)
            console.log(groupedData[0])
            this.setState({
                overview:data_2.product.product.shortDescription,
                images:data_2.product.product.images,
                specifications:groupedData
            })
        }catch (error) {
            this.setState({
                error
            })
        }
    }

    handleChangeFormFloat(e){
        this.setState({
            form_float:{
                ...this.state.form_float,
                [e.target.name]:e.target.value
            }
        })
    }

    formSubmitFloat = async (e) => {
        if(
            this.state.form_float.name == '' ||
            this.state.form_float.mail == '' ||
            this.state.form_float.phone == '' ||
            this.state.form_float.city == '' ||
            this.state.form_float.company == '' 
        ){
            swal({
                text: "Debes digitar los datos necesarios",
                icon: imageURL,
                button: "OK!",
            });
            return false;
        }

        if(this.state.form_float.check_terms == 'no'){
            swal({
                text: "Debes aceptar los terminos y condiciones",
                icon: imageURL,
                button: "OK!",
            });
            return false;
        }

        swal({
            text: "Espere un momento",
            icon: imageURL,
            button: false,
            closeOnClickOutside: false,
        });

        if(this.state.form_float.token == ""){
            const token = await this._recaptchaRef.current.executeAsync();
            this.setState({
                form_float:{
                    ...this.state.form_float,
                    token: token
                }
            });
            if(!token){
                swal({
                    text: "Error, eres un robot",
                    icon: "error",
                    button: "OK!",
                    closeOnClickOutside: false,
                });
                return false;
            }
        }

        try{
            let config = {
                method: 'POST',
                headers:{
                    'Accept':'application/json',
                    'Content-Type':'application/json'
                },
                body: JSON.stringify(this.state.form_float)
            }
            let res = await fetch(`/api/contact-float`,config)
            let data = await res.json()
            if(data == 1){
                this.setState({
                    form_float:{
                        name: '',
                        mail: '',
                        phone: '',
                        city: '',
                        company: '',
                        messague: '',
                        token:'',
                        check_terms: 'no',
                        page: '',
                        send_mail: '',
                    }
                })
                document.getElementById("form_float").reset();
                swal.close();
                swal({
                    text: "Gracias por contactarnos",
                    icon: "success",
                    button: "OK!",
                    dangerMode: false,
                    closeOnClickOutside: false,
                })
                    .then((willDelete) => {
                        if (willDelete) {
                            location.reload();
                        } else {

                        }
                    });
            }else{
                console.log(data)
                alert('ocurrio un error');
                setTimeout(function (){
                    //location.reload();
                },1000)
            }
        }catch (error) {
            this.setState({
                error
            })
        }
    }

    submitClickFloat(){
        this.formSubmitFloat()
    }

    termsFloat(){
        let value = ""
        if(this.state.form_float.check_terms == "no"){
            $('#check_terms_float').prop('checked',true)
            value = "si"
        }else{
            $('#check_terms_float').prop('checked',false)
            value = "no"
        }
        this.setState({
            form_float:{
                ...this.state.form_float,
                check_terms:value
            }
        })
    }

    changeNav(nav,selected){

        $('.selecteds').removeClass('selected')
        $('.'+selected).addClass('selected')

        $('.prodGallery').css('display','none')
        $('.product').css('display','none')
        $('.benefitsProd').css('display','none')
        $('.prodDocs').css('display','none')
        $('.v360').css('display','none')

        $('.'+nav).css('display','block')
    }

    render() {
        return (
            <div>
                <Header
                    products={this.state.products}
                    services={this.state.services}
                />
                <Menu
                    products={this.state.products}
                    services={this.state.services}
                />
                <Login
                />
                <main>
                    <Nav
                        change={this.changeNav}
                    />
                    <Info
                        list={this.state.specifications}
                    />
                    <Docs
                    />
                    <Gallery
                        overview={this.state.overview}
                        images={this.state.images}
                    />
                    <Benefits
                    />
                    <V360
                    />
                </main>
                <Float
                    config_form={this.state.config_form}

                    onChange={this.handleChangeFormFloat}
                    form={this.state.form_float}
                    submit={this.submitClickFloat}
                    recaptchaRef={this._recaptchaRef}
                    terms={this.termsFloat}
                />
                <Footer
                    products={this.state.products}
                    services={this.state.services}
                    info={this.state.info}
                />
            </div>
        );
    }

}

if (document.getElementById('content_product')) {
    root.render(
        <Product />
    )
}