import React from 'react'
import ReCAPTCHA from "react-google-recaptcha";

let vector = [0]

const Content = function content ({
        formlogin,onChangeLogin,recaptchaRefLogin,onSubmitLogin,submitClick
    }){
    return (
        <section className="login" style={{backgroundImage: 'url("/img/banners/prodbg.avif")'}} >
            <div className="container grid">
                <div className="form colflex">
                    <div className="tittle grid">
                        <h2>Bienvenido</h2>
                    </div>
                    <ReCAPTCHA
                        ref={recaptchaRefLogin}
                        size="invisible"
                        sitekey="6LeAu3cgAAAAAIhj6gFouDEtkkynaMvDRUtSJGM1"
                    />
                    <form 
                        id="formLogin" 
                        action="" 
                        onSubmit={onSubmitLogin} 
                        className="colflex"
                    >
                        <input
                            type="hidden"
                            name="token"
                            value={formlogin.token}
                            onChange={onChangeLogin}
                        />
                        <label>Usuario</label>
                        <input
                            name="user"
                            type="text"
                            value={formlogin.user}
                            onChange={onChangeLogin}
                        />
                        <label >Contraseña</label>
                        <input
                            name="password"
                            type="password"
                            value={formlogin.password}
                            onChange={onChangeLogin}
                        />
                        <a className="flex" onClick={()=>submitClick()}>
                            Acceder
                        </a>
                    </form>
                    <div className="logow grid">
                        <img src="/img/logo2.svg" alt="SDI"/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Content