import React from 'react'

let vector = [0]

const Content = function content ({url,info,list}){
    return (
        <section className="courseInt">
            <div className="container colflex">
                <a href={"/cursos-info/"+info.trainings_category_id} className="backTrain flex">
                    <img src="/img/icons/backblog.svg" alt="⬅️"/>
                    <span>Atrás</span>
                </a>
                <div className="contentCourse colflex">
                    <div className="info colflex">
                        <img src={url+info.img} alt="banner-course"/>
                        <div className="text colflex">
                            <h2>{info.title}</h2>
                            <div className="duration flex" style={{display:"none"}}>
                                <svg width="15" height="15" viewBox="0 0 20 20" fill="none" >
                                    <path d="M9.98145 0C4.4898 0 0 4.4898 0 9.98145C0 15.4731 4.4898 19.9629 9.98145 19.9629C15.4731 19.9629 19.9629 15.4731 19.9629 9.98145C19.9629 4.4898 15.5102 0 9.98145 0ZM9.98145 18.6271C5.23191 18.6271 1.33581 14.7681 1.33581 9.98145C1.33581 5.1948 5.23191 1.37291 9.98145 1.37291C14.731 1.37291 18.6271 5.23191 18.6271 10.0186C18.6271 14.8052 14.7681 18.6271 9.98145 18.6271Z" fill="black"/>
                                    <path d="M14.5454 9.53656H10.4637V4.52728C10.4637 4.15622 10.1669 3.85938 9.79583 3.85938C9.42478 3.85938 9.12793 4.15622 9.12793 4.52728V10.8724H10.0185H10.4637H14.5454C14.9164 10.8724 15.2133 10.5755 15.2133 10.2045C15.2133 9.8334 14.9164 9.53656 14.5454 9.53656Z" fill="black"/>
                                </svg>
                                <span>Duración: {info.duration}</span>
                            </div>
                            <p>{info.text_long}</p>
                        </div>
                    </div>

                    {vector.map((v)=>{
                        if(list.length > 0){
                            return(
                                <div key={v} className="requirements colflex">
                                    <h3>Areas de saber</h3>
                                    <div className="listRequirements grid">
                                        {list.map((l)=>{
                                            return(
                                                <p key={l.id}>{l.text}</p>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        }
                    })}
                    

                    <a href={url+info.pdf} target='_blank' 
                    className="downTem flex" style={{display:"none"}}>
                        <span>Descargar Temario</span>
                        <img src="/img/icons/downb.svg" alt="⬇️"/>
                    </a>

                </div>
            </div>
        </section>
    )
}

export default Content