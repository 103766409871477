import React,{useRef,Component} from 'react';
import { createRoot } from "react-dom/client";

import Header from "../components/header";
import Menu from "../components/menu";
import Footer from "../components/footer";

import Login from "../components/login";
import Float from "../components/float";

import Content from "../components/report/content";

const rootElement = document.getElementById("content_report");
const root = createRoot(rootElement);

export default class Report extends Component {
    constructor(props){
        super(props)

        this.state = {
            name: 'report',
            url_admin:[],
            info:[],
            products:[],
            services:[],
            config_form:[],
            error: null,
            text:[],
            form:{
                anony: 'no',
                vinc_prov: '',
                detail_report: '',
                date: '',
                place:'',
                hechos: '',
                persons:[],
                files:[],
                check_terms: 'no',
            },
            form_float:{
                name: '',
                mail: '',
                phone: '',
                city: '',
                company: '',
                messague: '',
                token:'',
                check_terms: 'no',
                page: '',
                send_mail: '',
            }
        }


        this.handleChangeForm = this.handleChangeForm.bind(this)
        this.formSubmit = this.formSubmit.bind(this)
        this.submitClick = this.submitClick.bind(this)
        this.terms = this.terms.bind(this)

        this._recaptchaRef = React.createRef()

        this.add_person = this.add_person.bind(this)
        this.delete_person = this.delete_person.bind(this)

        this.add_file = this.add_file.bind(this)
        this.delete_file = this.delete_file.bind(this)

        this.handleChangeFormFloat = this.handleChangeFormFloat.bind(this)
        this.formSubmitFloat = this.formSubmitFloat.bind(this)
        this.submitClickFloat = this.submitClickFloat.bind(this)
        this.termsFloat = this.termsFloat.bind(this)
    }

    async componentDidMount(){
        try{
            let res = await fetch('/get/report')
            let data = await res.json()
            this.setState({
                url_admin:data[0],
                info:data[1],
                products:data[2],
                services:data[3],
                config_form:data[4],
                text:data[5][9],
                form_float:{
                    ...this.state.form_float,
                    page: data[4].page,
                    send_mail: data[4].mail
                }
            })
        }catch (error) {
            this.setState({
                error
            })
        }
    }

    handleChangeForm(e){
        this.setState({
            form:{
                ...this.state.form,
                [e.target.name]:e.target.value
            }
        })
    }

    formSubmit = async (e) => {
        if(
            this.state.form.anony == '' ||
            this.state.form.vinc_prov == '' ||
            this.state.form.detail_report == '' ||
            this.state.form.date == '' ||
            this.state.form.place == '' ||
            this.state.form.hechos == ''
        ){
            swal({
                text: "Debes digitar los datos necesarios",
                icon: imageURL,
                button: "OK!",
            });
            return false;
        }

        if(this.state.form.check_terms == 'no'){
            swal({
                text: "Debes aceptar los terminos y condiciones",
                icon: imageURL,
                button: "OK!",
            });
            return false;
        }

        if(this.state.form.token == ""){
            const token = await this._recaptchaRef.current.executeAsync();
            this.setState({
                form:{
                    ...this.state.form,
                    token: token
                }
            });
            if(!token){
                swal({
                    text: "Error, eres un robot",
                    icon: "error",
                    button: "OK!",
                    closeOnClickOutside: false,
                });
                return false;
            }
        }

        swal({
            text: "Espere un momento",
            icon: imageURL,
            button: false,
            closeOnClickOutside: false,
        });

		const formData = new FormData();

        formData.append('anony', this.state.form.anony);
        formData.append('vinc_prov', this.state.form.vinc_prov);
        formData.append('detail_report', this.state.form.detail_report);
        formData.append('date', this.state.form.date);
        formData.append('place', this.state.form.place);
        formData.append('hechos', this.state.form.hechos);

        formData.append('persons', this.state.form.persons);

        let obj_file = ""
        let obj_type = ""
        formData.append('files_length', this.state.form.files.length);
        for(let i=0;i<this.state.form.files.length;i++){
            obj_file = this.state.form.files[i][0]
            obj_type = this.state.form.files[i][1]

            formData.append("file_"+i, obj_file);
            formData.append("type_"+i, obj_type);
        }

        try{
            fetch(
                '/api/contact-report',
                {
                    method: 'POST',
                    body: formData,
                }
            )
            .then((response) => response.json())
            .then((data) => {
                if(data == 1){
                    this.setState({
                        form:{
                            anony: 'no',
                            vinc_prov: '',
                            detail_report: '',
                            date: '',
                            place:'',
                            hechos: '',
                            persons:[],
                            files:[],
                            check_terms: 'no',
                        }
                    })
                    document.getElementById("formContact").reset();
                    swal.close();
                    swal({
                        text: "Gracias, reporte realizado",
                        icon: "success",
                        button: "OK!",
                        dangerMode: false,
                        closeOnClickOutside: false,
                    })
                    .then((willDelete) => {
                        if (willDelete) {
                            location.reload();
                        } else {

                        }
                    });
                }else{
                    console.log(data)
                    //alert('ocurrio un error');
                    setTimeout(function (){
                        //location.reload();
                    },1000)
                }
            })
        }catch (error) {
            this.setState({
                error
            })
        }
    }

    submitClick(){
        this.formSubmit()
    }

    terms(){
        let value = ""
        if(this.state.form.check_terms == "no"){
            $('#check_terms').prop('checked',true)
            value = "si"
        }else{
            $('#check_terms').prop('checked',false)
            value = "no"
        }
        this.setState({
            form:{
                ...this.state.form,
                check_terms:value
            }
        })
    }

    add_person(){
        if($('#add_person').val() == ""){
            return false
        }
        let count = this.state.form.persons.length
        let vector = this.state.form.persons
        vector[count] = $('#add_person').val()
        this.setState({
            form:{
                ...this.state.form,
                persons: vector
            }
        })
        $('#add_person').val("")
    }

    delete_person(id){
        let count = this.state.form.persons.length
        let vector = this.state.form.persons
        vector.splice(id, 1);
        this.setState({
            form:{
                ...this.state.form,
                persons: vector
            }
        })
    }

    add_file(){
        if($('#add_file').val() == ""){
            return false
        }

        var file = document.querySelector('input[name=add_file]').files[0];
        var reader = new FileReader();

        if (file) {
            reader.readAsDataURL(file);
        } else {
            return false
        }

        let count = this.state.form.files.length
        let vector = this.state.form.files
        vector[count] = [file,file.type,file.name]
        this.setState({
            form:{
                ...this.state.form,
                files: vector
            }
        })
        $('#add_file').val("")
    }

    delete_file(id){
        let count = this.state.form.files.length
        let vector = this.state.form.files
        vector.splice(id, 1);
        this.setState({
            form:{
                ...this.state.form,
                files: vector
            }
        })
    }

    handleChangeFormFloat(e){
        this.setState({
            form_float:{
                ...this.state.form_float,
                [e.target.name]:e.target.value
            }
        })
    }

    formSubmitFloat = async (e) => {
        if(
            this.state.form_float.name == '' ||
            this.state.form_float.mail == '' ||
            this.state.form_float.phone == '' ||
            this.state.form_float.city == '' ||
            this.state.form_float.company == '' 
        ){
            swal({
                text: "Debes digitar los datos necesarios",
                icon: imageURL,
                button: "OK!",
            });
            return false;
        }

        if(this.state.form_float.check_terms == 'no'){
            swal({
                text: "Debes aceptar los terminos y condiciones",
                icon: imageURL,
                button: "OK!",
            });
            return false;
        }

        swal({
            text: "Espere un momento",
            icon: imageURL,
            button: false,
            closeOnClickOutside: false,
        });

        if(this.state.form_float.token == ""){
            const token = await this._recaptchaRef.current.executeAsync();
            this.setState({
                form_float:{
                    ...this.state.form_float,
                    token: token
                }
            });
            if(!token){
                swal({
                    text: "Error, eres un robot",
                    icon: "error",
                    button: "OK!",
                    closeOnClickOutside: false,
                });
                return false;
            }
        }

        try{
            let config = {
                method: 'POST',
                headers:{
                    'Accept':'application/json',
                    'Content-Type':'application/json'
                },
                body: JSON.stringify(this.state.form_float)
            }
            let res = await fetch(`/api/contact-float`,config)
            let data = await res.json()
            if(data == 1){
                this.setState({
                    form_float:{
                        name: '',
                        mail: '',
                        phone: '',
                        city: '',
                        company: '',
                        messague: '',
                        token:'',
                        check_terms: 'no',
                        page: '',
                        send_mail: '',
                    }
                })
                document.getElementById("form_float").reset();
                swal.close();
                swal({
                    text: "Gracias por contactarnos",
                    icon: "success",
                    button: "OK!",
                    dangerMode: false,
                    closeOnClickOutside: false,
                })
                    .then((willDelete) => {
                        if (willDelete) {
                            location.reload();
                        } else {

                        }
                    });
            }else{
                console.log(data)
                alert('ocurrio un error');
                setTimeout(function (){
                    //location.reload();
                },1000)
            }
        }catch (error) {
            this.setState({
                error
            })
        }
    }

    submitClickFloat(){
        this.formSubmitFloat()
    }

    termsFloat(){
        let value = ""
        if(this.state.form_float.check_terms == "no"){
            $('#check_terms_float').prop('checked',true)
            value = "si"
        }else{
            $('#check_terms_float').prop('checked',false)
            value = "no"
        }
        this.setState({
            form_float:{
                ...this.state.form_float,
                check_terms:value
            }
        })
    }

    render() {
        return (
            <div>
                <Header
                    products={this.state.products}
                    services={this.state.services}
                />
                <Menu
                    products={this.state.products}
                    services={this.state.services}
                />
                <Login
                />
                <main>
                    <Content
                        text={this.state.text}

                        onChange={this.handleChangeForm}
                        form={this.state.form}
                        submit={this.submitClick}
                        recaptchaRef={this._recaptchaRef}
                        terms={this.terms}

                        add_person={this.add_person}
                        delete_person={this.delete_person}

                        add_file={this.add_file}
                        delete_file={this.delete_file}
                    />
                </main>
                <Float
                    config_form={this.state.config_form}

                    onChange={this.handleChangeFormFloat}
                    form={this.state.form_float}
                    submit={this.submitClickFloat}
                    recaptchaRef={this._recaptchaRef}
                    terms={this.termsFloat}
                />
                <Footer
                    products={this.state.products}
                    services={this.state.services}
                    info={this.state.info}
                />
            </div>
        );
    }

}

if (document.getElementById('content_report')) {
    root.render(
        <Report />
    )
}