import React,{useRef,Component} from 'react';
import { createRoot } from "react-dom/client";

import Header from "../components/header";
import Menu from "../components/menu";
import Footer from "../components/footer";

import Login from "../components/login";
import Float from "../components/float";

import Banner from "../components/index/banner";
import Products from "../components/index/products";
import Services from "../components/index/services";
import History from "../components/index/history";
import Sustain from "../components/index/sustain";
import Contact from "../components/index/contact";
import Blog from "../components/index/blog";
import Follow from "../components/index/follow";

const rootElement = document.getElementById("content_index");
const root = createRoot(rootElement);

export default class Index extends Component {
    constructor(props){
        super(props)

        this.state = {
            name: 'index',
            url_admin:[],
            info:[],
            products:[],
            services:[],
            config_form:[],
            text_1:[],
            text_2:[],
            text_3:[],
            text_4:[],
            text_5:[],
            text_6:[],
            banner:[],
            blog:[],
            error: null,
            form:{
                name: '',
                phone: '',
                mail: '',
                messague: '',
                token:'',
                check_terms: 'no',
            },
            form_float:{
                name: '',
                mail: '',
                phone: '',
                city: '',
                company: '',
                messague: '',
                token:'',
                check_terms: 'no',
                page: '',
                send_mail: '',
            },
            instagram:[]
        }

        this.handleChangeForm = this.handleChangeForm.bind(this)
        this.formSubmit = this.formSubmit.bind(this)
        this.submitClick = this.submitClick.bind(this)
        this.terms = this.terms.bind(this)

        this._recaptchaRef = React.createRef()

        this.handleChangeFormFloat = this.handleChangeFormFloat.bind(this)
        this.formSubmitFloat = this.formSubmitFloat.bind(this)
        this.submitClickFloat = this.submitClickFloat.bind(this)
        this.termsFloat = this.termsFloat.bind(this)
    }

    async componentDidMount(){
        try{
            let res = await fetch('/get/home')
            let data = await res.json()
            this.setState({
                url_admin:data[0],
                info:data[1],
                products:data[2],
                services:data[3],
                config_form:data[4],
                text_1:data[5][0],
                text_2:data[5][1],
                text_3:data[5][2],
                text_4:data[5][3],
                text_5:data[5][4],
                text_6:data[5][5],
                banner:data[6],
                blog:data[7],
                form_float:{
                    ...this.state.form_float,
                    page: data[4].page,
                    send_mail: data[4].mail
                }
            })


            let res_2 = await fetch('/instagram')
            let data_2 = await res_2.json()
            this.setState({
                instagram:data_2
            })
        }catch (error) {
            this.setState({
                error
            })
        }
    }


    handleChangeForm(e){
        this.setState({
            form:{
                ...this.state.form,
                [e.target.name]:e.target.value
            }
        })
    }

    formSubmit = async (e) => {
        if(
            this.state.form.name == '' ||
            this.state.form.phone == '' ||
            this.state.form.mail == ''
        ){
            swal({
                text: "Debes digitar los datos necesarios",
                icon: imageURL,
                button: "OK!",
            });
            return false;
        }

        if(this.state.form.check_terms == 'no'){
            swal({
                text: "Debes aceptar los terminos y condiciones",
                icon: imageURL,
                button: "OK!",
            });
            return false;
        }

        swal({
            text: "Espere un momento",
            icon: imageURL,
            button: false,
            closeOnClickOutside: false,
        });

        if(this.state.form.token == ""){
            const token = await this._recaptchaRef.current.executeAsync();
            this.setState({
                form:{
                    ...this.state.form,
                    token: token
                }
            });
            if(!token){
                swal({
                    text: "Error, eres un robot",
                    icon: "error",
                    button: "OK!",
                    closeOnClickOutside: false,
                });
                return false;
            }
        }

        try{
            let config = {
                method: 'POST',
                headers:{
                    'Accept':'application/json',
                    'Content-Type':'application/json'
                },
                body: JSON.stringify(this.state.form)
            }
            let res = await fetch(`/api/contact-home`,config)
            let data = await res.json()
            if(data == 1){
                this.setState({
                    form:{
                        name: '',
                        phone: '',
                        mail: '',
                        messague: '',
                        token:'',
                        check_terms: 'no',
                    }
                })
                document.getElementById("formContact").reset();
                swal.close();
                swal({
                    text: "Gracias por contactarnos",
                    icon: "success",
                    button: "OK!",
                    dangerMode: false,
                    closeOnClickOutside: false,
                })
                    .then((willDelete) => {
                        if (willDelete) {
                            location.reload();
                        } else {

                        }
                    });
            }else{
                console.log(data)
                alert('ocurrio un error');
                setTimeout(function (){
                    //location.reload();
                },1000)
            }
        }catch (error) {
            this.setState({
                error
            })
        }
    }

    submitClick(){
        this.formSubmit()
    }

    terms(){
        let value = ""
        if(this.state.form.check_terms == "no"){
            $('#check_terms').prop('checked',true)
            value = "si"
        }else{
            $('#check_terms').prop('checked',false)
            value = "no"
        }
        this.setState({
            form:{
                ...this.state.form,
                check_terms:value
            }
        })
    }

    handleChangeFormFloat(e){
        this.setState({
            form_float:{
                ...this.state.form_float,
                [e.target.name]:e.target.value
            }
        })
    }

    formSubmitFloat = async (e) => {
        if(
            this.state.form_float.name == '' ||
            this.state.form_float.mail == '' ||
            this.state.form_float.phone == '' ||
            this.state.form_float.city == '' ||
            this.state.form_float.company == '' 
        ){
            swal({
                text: "Debes digitar los datos necesarios",
                icon: imageURL,
                button: "OK!",
            });
            return false;
        }

        if(this.state.form_float.check_terms == 'no'){
            swal({
                text: "Debes aceptar los terminos y condiciones",
                icon: imageURL,
                button: "OK!",
            });
            return false;
        }

        swal({
            text: "Espere un momento",
            icon: imageURL,
            button: false,
            closeOnClickOutside: false,
        });

        if(this.state.form_float.token == ""){
            const token = await this._recaptchaRef.current.executeAsync();
            this.setState({
                form_float:{
                    ...this.state.form_float,
                    token: token
                }
            });
            if(!token){
                swal({
                    text: "Error, eres un robot",
                    icon: "error",
                    button: "OK!",
                    closeOnClickOutside: false,
                });
                return false;
            }
        }

        try{
            let config = {
                method: 'POST',
                headers:{
                    'Accept':'application/json',
                    'Content-Type':'application/json'
                },
                body: JSON.stringify(this.state.form_float)
            }
            let res = await fetch(`/api/contact-float`,config)
            let data = await res.json()
            if(data == 1){
                this.setState({
                    form_float:{
                        name: '',
                        mail: '',
                        phone: '',
                        city: '',
                        company: '',
                        messague: '',
                        token:'',
                        check_terms: 'no',
                        page: '',
                        send_mail: '',
                    }
                })
                document.getElementById("form_float").reset();
                swal.close();
                swal({
                    text: "Gracias por contactarnos",
                    icon: "success",
                    button: "OK!",
                    dangerMode: false,
                    closeOnClickOutside: false,
                })
                    .then((willDelete) => {
                        if (willDelete) {
                            location.reload();
                        } else {

                        }
                    });
            }else{
                console.log(data)
                alert('ocurrio un error');
                setTimeout(function (){
                    //location.reload();
                },1000)
            }
        }catch (error) {
            this.setState({
                error
            })
        }
    }

    submitClickFloat(){
        this.formSubmitFloat()
    }

    termsFloat(){
        let value = ""
        if(this.state.form_float.check_terms == "no"){
            $('#check_terms_float').prop('checked',true)
            value = "si"
        }else{
            $('#check_terms_float').prop('checked',false)
            value = "no"
        }
        this.setState({
            form_float:{
                ...this.state.form_float,
                check_terms:value
            }
        })
    }

    render() {
        return (
            <div>
                <Header
                    products={this.state.products}
                    services={this.state.services}
                />
                <Menu
                    products={this.state.products}
                    services={this.state.services}
                />
                <Login
                />
                <main>
                    <Banner
                        url={this.state.url_admin}
                        list={this.state.banner}
                    />
                    <Products
                        url={this.state.url_admin}
                        text={this.state.text_1}
                        list={this.state.products}
                    />
                    <Services
                        url={this.state.url_admin}
                        text={this.state.text_2}
                        list={this.state.services}
                    />
                    <History
                        url={this.state.url_admin}
                        text={this.state.text_3}
                    />
                    <Sustain
                        url={this.state.url_admin}
                        text={this.state.text_4}
                    />
                    <Contact
                        url={this.state.url_admin}
                        text={this.state.text_5}

                        onChange={this.handleChangeForm}
                        form={this.state.form}
                        submit={this.submitClick}
                        recaptchaRef={this._recaptchaRef}
                        terms={this.terms}
                    />
                    <Blog
                        url={this.state.url_admin}
                        text={this.state.text_6}
                        list={this.state.blog}
                    />
                    <Follow
                        info={this.state.info}
                        instagram={this.state.instagram}
                    />
                </main>
                <Float
                    config_form={this.state.config_form}

                    onChange={this.handleChangeFormFloat}
                    form={this.state.form_float}
                    submit={this.submitClickFloat}
                    recaptchaRef={this._recaptchaRef}
                    terms={this.termsFloat}
                />
                <Footer
                    products={this.state.products}
                    services={this.state.services}
                    info={this.state.info}
                />
            </div>
        );
    }

}

if (document.getElementById('content_index')) {
    root.render(
        <Index />
    )
}