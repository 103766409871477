import React from 'react'

let vector = [0]
let validate = 1

const Blog = function blog ({url,text,list}){
    return (
        <section className="blogHome">
            <div className="content">
                <h1>{text.title}</h1>
                <p dangerouslySetInnerHTML={{__html: text.text}}></p>
                <div className="listPostHome" id="listPostHome">
                    {list.map((l)=>{
                        let date = l.created_at.split("T")
                        return(
                            <div className="item colflex" key={l.id}>
                                <a href={"/lo-ultimo/"+l.slug} className="thumb flex">
                                    <img src={url+l.img} alt="post-img"/>
                                </a>
                                <div className="text colflex">
                                    <a href={"/lo-ultimo/"+l.slug}>{l.title}</a>
                                    <span>{date[0]}</span>
                                </div>
                            </div>
                        )
                    })}
                </div>
                {vector.map((v)=>{
                    if(list.length > 0 && validate == 1){
                        setTimeout(()=>{
                            $('#listPostHome').slick({
                                slidesToShow: 1,
                                arrows: false,
                                mobileFirst: true,
                                centerMode: true,
                                responsive: [
                                    {
                                    breakpoint: 700,
                                    settings: {
                                        slidesToShow: 3,
                                        centerMode: true
                                    }
                                    },
                                    {
                                    breakpoint: 1020,
                                        settings: 'unslick'
                                    },
                                ]
                            });
                        },10)
                    }
                })}
                <a href={"/lo-ultimo"} className="seeMore flex">
                    <span>Ver todos</span>
                    <img src="/img/icons/righty.svg" alt="➡️"/>
                </a>
            </div>
        </section>
    )
}

export default Blog