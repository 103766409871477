import React from 'react'

let vector = [0]

const Modal = function modal ({
    closeModal,copyUrl,closeModal_2,
    apiWhat,apiFace,apiTwi
    }){
    return (
        <div
            className="jquery-modal blocker current" 
            style={{display:'none'}} 
            onClick={(e)=>closeModal(e)}
            id={"modalShare"}
        >
        <div 
            className="modal modalShare" id="modalShare_m"
            style={{opacity: 1,display: "inline-block"}}
        >
            <a
                className="close-modal" 
                onClick={()=>closeModal_2()}
            >
                Close
            </a>
            <h4>COMPARTIR EN:</h4>
            <div className="container grid">
                <a onClick={()=>copyUrl()} className="item colflex">
                    <img src="/img/share/copy.svg" alt=""/>
                    <span>Copiar<br/>enlance</span>
                </a>
                <a onClick={()=>apiWhat()} className="item colflex">
                    <img src="/img/share/wpp.svg" alt=""/>
                    <span>Whatsapp</span>
                </a>
                <a  
                    href="" 
                    className="item colflex" 
                    style={{display: "none"}}
                >
                    <img src="/img/share/insta.svg" alt=""/>
                    <span>Instagram</span>
                </a>
                <a onClick={()=>apiFace()} className="item colflex">
                    <img src="/img/share/fb.svg" alt=""/>
                    <span>Facebook</span>
                </a>
                <a onClick={()=>apiTwi()} className="item colflex">
                    <img src="/img/share/tw.svg" alt=""/>
                    <span>Twitter</span>
                </a>
            </div>
        </div>
        </div>
    )
}

export default Modal