import React from 'react'

let vector = [0]
let validate = 1

const Follow = function follow ({info,instagram}){
    let count = 0
    return (
        <section className="followHome">
            <div className="content colflex">
                <h1>SÍGUENOS</h1>
                <a className="title colflex" href={info.instagram_url} target='_blank'>
                    <img src="/img/icons/insta2.svg" alt="insta-icon"/>
                    <span>{info.instagram_user}</span>
                </a>
                <div className="listPosts" id="listIGPosts">
                    {instagram.map((i)=>{
                        count++
                        return(
                            <div className="item" key={count}>
                                <a href={i.url} className='flex'>
                                    <img src={i.image} alt="img"/>
                                </a>
                            </div>
                        )
                    })}
                </div>
                {vector.map((v)=>{
                    if(instagram.length > 0 && validate == 1){
                    setTimeout(()=>{
                        $('#listIGPosts').slick({
                            slidesToShow: 1,
                            arrows: false,
                            mobileFirst: true,
                            centerMode: true,
                            responsive: [
                              {
                                breakpoint: 700,
                                  settings: 'unslick'
                              },
                            ]
                        });
                    },10)
                    }
                })}
                <div className="social colflex">
                    <a href={info.facebook_url} className="colflex" target='_blank'>
                        <img src="/img/icons/fb2.svg" alt=""/>
                        <span>{info.facebook_user}</span>
                    </a>
                    <a href={info.linkedin_url} className="colflex" target='_blank'>
                        <img src="/img/icons/linke2.svg" alt=""/>
                        <span>{info.linkedin_user}</span>
                    </a>
                    <a href={info.youtube_url} className="colflex" target='_blank'>
                        <img className="youtube" src="/img/icons/yt3.svg" alt=""/>
                        <span>{info.youtube_user}</span>
                    </a>
                </div>
            </div>
        </section>
    )
}

export default Follow