import React from 'react'
import ReCAPTCHA from "react-google-recaptcha";

let vector = [0]
let count_person = -1
let count_file = -1

const Content = function content ({
    text,
    onChange,form,submit,recaptchaRef,terms,
    add_person,delete_person,
    add_file,delete_file
}){
    count_person = -1
    count_file = -1
    return (
        <section className="request">
            <div className="container colflex">
                <div className="text colflex">
                    <h1>{text.title}<span>{text.subtitle}</span></h1>
                    <p dangerouslySetInnerHTML={{__html: text.text}}></p>
                </div>

                <form className="colflex" id="formContact">
                    <div className="denun item colflex">
                        <h2>Información general</h2>
                        <p>¿La denuncia se realizará de manera anónima?</p>
                        <div className="checks flex">
                            <label htmlFor="anony_si">
                                Si
                                <input
                                    id="anony_si"
                                    type="radio"
                                    name="anony"
                                    value="si"
                                    onChange={onChange}
                                />
                            </label>
                            <label htmlFor="anony_no">
                                No
                                <input
                                    id="anony_no"
                                    type="radio"
                                    name="anony"
                                    value="no"
                                    onChange={onChange}
                                />
                            </label>
                        </div>
                        <hr className="separator"/>
                        <p>Vínculo con la compañía</p>
                        <select name="vinc_prov" onChange={onChange}>
                            <option value="">Seleccionar</option>
                            <option value="Empleado">Empleado</option>
                            <option value="Cliente">Cliente</option>
                            <option value="Proveedor">Proveedor</option>
                            <option value="Contratista">Contratista</option>
                            <option value="Otro">Otro</option>
                        </select>
                    </div>

                    <div className="item colflex">
                        <h2>DETALLES DE DENUNCIA</h2>
                        <p>Objeto de la denuncia</p>
                        <input 
                            type="text" 
                            placeholder="Escriba aquí..."
                            name="detail_report"
                            value={form.detail_report}
                            onChange={onChange}
                        />
                        <hr className="separator"/>
                        <p>Fecha de los hechos</p>
                        <div className="calendar relative">
                            <input 
                                id="placeFacts"
                                type="date" 
                                name="date"
                                value={form.date}
                                onChange={onChange}
                            />
                            <img src="/img/icons/calendar.svg" alt="📆"/>
                        </div>
                        <hr className="separator"/>
                        <p>Lugar de los hechos</p>
                        <input 
                            type="text" 
                            placeholder="Escriba aquí..."
                            name="place"
                            value={form.place}
                            onChange={onChange}
                        />
                        <hr className="separator"/>
                        <p>Hechos de la denuncia. <span>(Identificar si los hechos están por suceder).</span></p>
                        <textarea 
                            rows="6"
                            placeholder="Escriba aquí..."
                            name="hechos"
                            value={form.hechos}
                            onChange={onChange}
                        ></textarea>
                    </div>

                    <div className="item colflex">
                        <h2>PERSONA(S) DENUNCIADA(S)</h2>
                        <p>Persona denunciada</p>
                        {form.persons.map((p)=>{
                            count_person++
                            let data = count_person
                            return(
                                <div className="file flex" key={count_person}>
                                    <span>{p}</span>
                                    <img src="/img/icons/close2.svg" alt="X-image" onClick={()=>delete_person(data)}/>
                                </div>
                            )
                        })}
                        <div className="upload flex relative">
                            <input 
                                id="add_person" 
                                type="text" 
                                placeholder="Agregar otro" 
                            />
                            <img onClick={()=>add_person()} src="/img/icons/plus.svg" alt="+-image"/>
                        </div>
                    </div>

                    <div className="item colflex">
                        <h2>DOCUMENTO ADJUNTO</h2>
                        <p>Agregue a continuación los documentos</p>
                        {form.files.map((f)=>{
                            count_file++
                            let data = count_file
                            return(
                                <div className="file flex" key={count_file}>
                                    <span>Documento #{count_file + 1} - {f[2]}</span>
                                    <img src="/img/icons/close2.svg" alt="X-image" onClick={()=>delete_file(data)}/>
                                </div>
                            )
                        })}
                        <div className="upload flex relative">
                            <input 
                                id="add_file" 
                                name="add_file"
                                type="file" 
                                placeholder="Agregar otro" 
                            />
                        </div>
                        <a onClick={()=>add_file()} className="button_add">
                            Agregar Archivo
                        </a>
                    </div>

                    <div className="item colflex">
                        <h2>TÉRMINOS Y CONDICIONES</h2>
                        <p>Términos y condiciones legales:</p>
                        <div className="policy colflex">
                            <div className="text grid">
                                <input
                                    id="check_terms"
                                    type="checkbox"
                                    onClick={()=>terms()}
                                />
                                <label htmlFor="check_terms">Al marcar la casilla, se entiende que ha leído, aceptado y autorizado el Tratamiento de sus Datos Personales de acuerdo con nuestra Política de Tratamiento de Información. 
                                <a href={"/autorizacion-tratamiendo-de-datos-personales"} target='_blank'>
                                    Ver Autorización Tratamiento De Datos Personales.
                                </a>
                                </label>
                            </div>
                            <a onClick={()=>submit()} className="btnFormModal">
                                Enviar
                            </a>
                        </div>
                    </div>

                </form>
            </div>
        </section>
    )
}

export default Content