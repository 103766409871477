import React from 'react'

let vector = [0]

const History = function history ({url,text}){
    return (
        <div>
        <img src="/img/truckbg.avif" className="bannerMid" alt="banner-mid"/>
        <section className="historyHome" style={{backgroundImage: "url(/img/truckbg.avif)"}}>
            <div className="container colflex">
                <img src={url+text.img} alt="truck-img" className="aniRtl"/>
                <div className="text colflex">
                    <h2 className="aniBt"><span>{text.title} </span>{text.subtitle}</h2>
                    <p className="aniBt" dangerouslySetInnerHTML={{__html: text.text}}></p>
                    <a href={"/somos-relianz"} className="flex aniLtr">
                        <span>Saber más...</span>
                        <img src="/img/icons/righty.svg" alt="➡️"/>
                    </a>
                </div>
            </div>
        </section>
        </div>
    )
}

export default History