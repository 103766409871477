import React from 'react'

let vector = [0]
let validate = 1

const Default = function defaultdiv ({url,list,text}){
    return (
        <section className="values" style={{backgroundImage: "url("+url+text.img+")"}}>
            <div className="content">
                <h2>{text.title}</h2>
                <p>{text.text}</p>
                <div className="sliderValues" id="sliderValues">
                    {list.map((l)=>{
                        return(
                            <div className="item" key={l.id}>
                                 <div class="dnone mask">
                                    <img src={url+l.img} alt="icon"/>
                                    <h3>{l.title}</h3>
                                </div>
                                <div className="title flex">
                                    <h3>{l.title}</h3>
                                    <img src={url+l.img} alt="icon"/>
                                </div>
                                <h4>{l.subtitle}</h4>
                                <div className="text">
                                    <p>{l.text}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>

                {vector.map((v)=>{
                    if(list.length > 0 && validate == 1){
                        setTimeout(()=>{
                            $('#sliderValues').slick({
                                slidesToShow: 1,
                                arrows: false,
                                mobileFirst: true,
                                centerMode: true,
                                variableWidht: true,
                                responsive: [
                                  {
                                    breakpoint: 760,
                                    settings: {
                                      variableWidht: true,
                                      slidesToShow: 2,
                                    }
                                  },
                                  {
                                    breakpoint: 1020,
                                    settings: 'unslick'
                                  },
                                ]
                            });
                        },10)
                    }
                })}
            </div>
        </section>
    )
}

export default Default