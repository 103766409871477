import React from 'react'

let vector = [0]

const Trainers = function trainers ({url,text,trainer}){
    return (
        <section className="trainers" style={{display:"none"}}>
            <div className="container colflex">
                <h1>{text.title}</h1>
                <p>{text.text}</p>
                
                <div className="trainerList colflex">

                    {trainer.map((t)=>{
                        return(
                            <div key={t.id} className="item colflex">
                                <div className="info colflex">
                                    <img src={url+t.img} alt="profile-image"/>
                                    <div className="text colflex">
                                        <h3>{t.title}</h3>
                                        <p>{t.subtitle}</p>
                                    </div>
                                </div>
                                <a href={"/entrenador/"+t.id} className="flex">
                                    <span>Saber más</span>
                                    <svg width="9" height="13" viewBox="0 0 9 13" fill="none">
                                    <path d="M1.75 12L7.25 6.5L1.75 1" stroke="#FFCC00" strokeWidth="2" strokeLinecap="round"/>
                                    </svg>
                                </a>
                            </div>
                        )
                    })}
                    
                </div>
            </div>
        </section>
    )
}

export default Trainers