import React from 'react'

let vector = [0]
let validate = 1

const Gallery = function gallery ({overview,images}){
    return (
        <section className="prodGallery">
            <div className="container">
                <p dangerouslySetInnerHTML={{__html: overview}}>
                </p>
                <div className="slickProd">
                    <div id="slickProd" className="bigSlick">
                        {images.map((v)=>{
                            return(
                                <img key={v.id} src={v.url} alt={v.title}/>
                            )
                        })}
                    </div>
                    <div id="altSlickProd" className="altSlickProd">
                        {images.map((v)=>{
                            return(
                                <img key={v.id} src={v.url} alt={v.title}/>
                            )
                        })}
                    </div>
                </div>

                {vector.map((v)=>{
                    if(images.length > 0 && validate == 1){
                        setTimeout(()=>{
                            $('#slickProd').slick({
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                arrows: false,
                                fade: true,
                                asNavFor: '#altSlickProd'
                            });
                            $('#altSlickProd').slick({
                                slidesToShow: 3,
                                slidesToScroll: 1,
                                asNavFor: '#slickProd',
                                arrows: false,
                                mobileFirst: true,
                                focusOnSelect: true,
                                responsive: [
                                  {
                                    breakpoint: 760,
                                    settings: {
                                      vertical: true,
                                      slidesToShow: 4,
                                    }
                                  },
                                  {
                                    breakpoint: 1000,
                                    settings: {
                                      vertical: true,
                                      slidesToShow: 5,
                                    }
                                  },
                                ]
                            });
                        },1000)
                    }
                })}
                <div className="text">
        
                </div>
            </div>
        </section>
    )
}

export default Gallery