setTimeout(function(){
    //GET HEIGHT HEADER FOR AUTO SEPARATOR
    //document.addEventListener('DOMContentLoaded', () => {
    //    const header = document.getElementById('header');
    //    const sepHeader = document.getElementById('separatorHeader');
        
    //    if (header && sepHeader) {
    //      const heightHeader = header.offsetHeight;
    //      sepHeader.style.height = `${heightHeader}px`;
    //    }
    //});

    /*===== MOBILE MENU SHOW & HIDDEN =====*/
    const navMenu = document.getElementById('nav-menu'),
    openMenu = document.getElementById('nav-toggle'),
    closeMenu = document.getElementById('nav-close')

    /*SHOW*/
    openMenu.addEventListener('click', () => {
    navMenu.classList.toggle('show2')
    })

    /*HIDDEN*/
    closeMenu.addEventListener('click', () => {
    navMenu.classList.remove('show2')
    })

    /*===== FORM SHOW & HIDDEN =====*/
    const formFloat = document.getElementById('formFloat'),
    openformFloat = document.getElementsByClassName('openFormMore'),
    closeformFloat = document.getElementById('form-close'),
    black = document.getElementById('black')

    for (let i = 0; i < openformFloat.length; i++) {
    openformFloat[i].addEventListener('click', function() {
        formFloat.classList.add('show')
        navMenu.classList.remove('show2')
        black.classList.add('show')
        });
    }
    /*HIDDEN*/
    closeformFloat.addEventListener('click', () => {
    formFloat.classList.remove('show')
    black.classList.remove('show')
    })
    black.addEventListener('click', () => {
        formFloat.classList.remove('show')
        black.classList.remove('show')
    })

    ////MENU HEADER
    const menuItem1 = document.getElementById('menuItem1'),
        menuItem2 = document.getElementById('menuItem2'),
        menuItem3 = document.getElementById('menuItem3'),
        menuItem4 = document.getElementById('menuItem4'),
        menu1 = document.getElementById('menu1'),
        menu2 = document.getElementById('menu2'),
        menu3 = document.getElementById('menu3'),
        menu4 = document.getElementById('menu4')

    function toggleMenu(content) {
    const menu = document.getElementById('menuHead');
    if (menu.style.display === 'flex' && content.style.display === 'flex') {
        menu.style.display = 'none';
        content.style.display = 'none';
    } else {
        // Oculta el menú anterior antes de mostrar el nuevo
        menu1.style.display = 'none';
        menu2.style.display = 'none';
        menu3.style.display = 'none';
        menu4.style.display = 'none';
        menuItem1.classList.remove('rotate')
        menuItem2.classList.remove('rotate')
        menuItem3.classList.remove('rotate')
        menuItem4.classList.remove('rotate')

        // Muestra el contenido específico del menú
        content.style.display = 'flex';
        menu.style.display = 'flex';
    }
    }

    function closeMenuHead() {
    const menu = document.getElementById('menuHead');
    if (menu.style.display === 'flex') {
        menu.style.display = 'none';
        menu1.style.display = 'none';
        menu2.style.display = 'none';
        menu3.style.display = 'none';
        menu4.style.display = 'none';
        menuItem1.classList.remove('rotate')
        menuItem2.classList.remove('rotate')
        menuItem3.classList.remove('rotate')
        menuItem4.classList.remove('rotate')
    }
    }

    menuItem1.addEventListener('click', () => {
    toggleMenu(menu1)
    menuItem1.classList.toggle('rotate')
    });
    menuItem2.addEventListener('click', () => {
    toggleMenu(menu2)
    menuItem2.classList.toggle('rotate')
    });
    menuItem3.addEventListener('click', () => {
    toggleMenu(menu3)
    menuItem3.classList.toggle('rotate')
    });
    menuItem4.addEventListener('click', () => {
    toggleMenu(menu4)
    menuItem4.classList.toggle('rotate')
    });
    document.addEventListener('scroll', () => closeMenuHead());


    // let currentMenu = null;
    // let currentButton = null;

    // function toggleMenu(button, menuId) {
    //   if (currentMenu) {
    //     currentMenu.classList.remove('show');
    //     currentButton.classList.remove('active');
    //     document.body.classList.remove('darken');
    //   }
    
    //   const menu = document.getElementById(menuId);
    //   const isShowing = menu.classList.contains('show');
    //   if (isShowing) {
    //     menu.classList.remove('show');
    //     button.classList.remove('active');
    //     document.body.classList.remove('darken');
    //   } else {
    //     menu.classList.add('show');
    //     button.classList.add('active');
    //     document.body.classList.add('darken');
    //   }
    //   currentMenu = !isShowing ? menu : null;
    //   currentButton = !isShowing ? button : null;
    // }

    // window.onclick = function(event) {
    //   if (!event.target.matches('button')) {
    //     if (currentMenu) {
    //       currentMenu.classList.remove('show');
    //       currentButton.classList.remove('active');
    //       document.body.classList.remove('darken');
    //       currentMenu = null;
    //       currentButton = null;
    //     }
    //   }
    // }

    // document.getElementById('menuItem1').addEventListener('click', function() { toggleMenu(this, 'menu1') });
    // document.getElementById('menuItem2').addEventListener('click', function() { toggleMenu(this, 'menu2') });
    // document.getElementById('menuItem3').addEventListener('click', function() { toggleMenu(this, 'menu3') });
    // document.getElementById('menuItem4').addEventListener('click', function() { toggleMenu(this, 'menu4') });

    ////END MENU HEADER

    ///////OPEN ACCORDION////////
    let acc = document.getElementsByClassName('accTittle'),
        content = document.getElementsByClassName('accContent')

    if (acc){
    for (i=0; i<acc.length; i++){
        acc[i].addEventListener('click', function(){
        this.classList.toggle('rotateImg')
        let content = this.nextElementSibling
        content.classList.toggle('openedContent')
        // document.body.classList.toggle('lock-screen')
        })
    }
    }


    const contactFlat = document.getElementById('flat')
    if (contactFlat && window.matchMedia('(min-width: 1280px)').matches ){
    window.addEventListener('scroll', function() {
        contactFlat.classList.toggle('hidden', window.scrollY < 200)
    }
    )} 


    // MODALS
    // $('a.openFormMore').click(function () {
    //   $(this).modal({
    //     fadeDuration: 150,
    //     clickClose: false,
    //     showClose: false,
    //     closeClass: 'modalClose'
    //   });
    //   return false;
    // });

    $('a.openLoginModal').click(function () {
    navMenu.classList.remove('show2')
    $(this).modal({
        fadeDuration: 150,
        clickClose: false,
        showClose: false,
        closeClass: 'modalClose'
    });
    return false;
    });

    $('a.openShareModal').click(function () {
    $(this).modal({
        fadeDuration: 150,
        clickClose: false,
    });
    return false;
    });

    // up
    const up = document.getElementById('upBtn')
    up.addEventListener('click',function(){
    window.scroll({
        top: 0,
        behavior: "smooth"
    });
    })

    // reveal text trainer

    const moreText = document.getElementById('moreText')
    if(moreText){
        moreText.addEventListener('click', function(){
        const textReveal = moreText.previousElementSibling
        textReveal.classList.toggle('dnone')
        const dots = textReveal.previousElementSibling
        dots.classList.toggle('dnone')
        moreText.classList.toggle('dnone')
    })
    }

    const currentDate = new Date();
    const currentDayOfMonth = currentDate.getDate()
    const currentMonth = currentDate.getMonth() // Be careful! January is 0, not 1
    const currentYear = currentDate.getFullYear()
    let dateControl = document.querySelector('#placeFacts[type="date"]');


    if (dateControl){
    const dateString = currentYear + "-" + (currentMonth + 1) + "-" + (currentDayOfMonth + 1)
    dateControl.min = dateString
    }

    //ANIMATIONS
    //const sr = ScrollReveal({
    //  distance: '30px',
    //  duration: 850,
    //  delay: 150,
    //  reset: true
    //})

    // sr.reveal(`.aniRtl` , {origin: 'right'})
    // sr.reveal(`.aniLtr` , {origin: 'left'})
    // sr.reveal(`.aniCt` , {origin: 'center'})
    // sr.reveal(`.aniTop` , {origin: 'top'})
    // sr.reveal(`.aniBt` , {origin: 'bottom'})

    // sr.reveal(`.services .content img` , {origin: 'left', mobile: false})

    // sr.reveal(`.bannerService .info h1` , {origin: 'left', mobile: false})

    // sr.reveal(`.galleryService .text` , {origin: 'left', mobile: false})

    // sr.reveal(`.productsHome .item` , {origin: 'top'})
    // sr.reveal(`.listServicesHome .item` , {origin: 'left', mobile: false, interval: 100})
    // sr.reveal(`.socialMob .social a` , {origin: 'left', interval: 100})

    const moreTxtElements = document.querySelectorAll('.moreTxt');
    moreTxtElements.forEach((moreTxt) => {
        moreTxt.addEventListener('click', () => {
            const txtServItem = moreTxt.previousElementSibling;
            if (txtServItem.classList.contains('opened')) {
                // Si está expandido, volver al tamaño inicial
                //txtServItem.style.height = '8rem'
                txtServItem.classList.remove('opened')
                moreTxt.textContent = 'Ver más'
            } else {
                // Si no está expandido, mostrar todo el contenido
                txtServItem.classList.add('opened')
                moreTxt.textContent = 'Ver menos'
                //txtServItem.style.height = 'auto';
            }
        });
    });
},100)