import React from 'react'

let vector = [0]

const Login = function login ({}){
    return (
        <div className="modal loginModal" id="loginModal" >
            <a href="#" className="close flex absolute" rel="modal:close">
                <img src="/img/icons/closemodal.svg" alt="X"/>
            </a>
            <div className="content colflex">
                <h2>Iniciar sesión</h2>
                <form className="colflex">
                    <label htmlFor="">Correo</label>
                    <input type="email" placeholder="nombre@example.com"/>
                    <label htmlFor="">Contraseña</label>
                    <input type="password" placeholder="*****"/>
                    <div className="forgot colflex">
                        <p>
                            ¿Olvidaste tu contraseña? <br/>
                            <a href="">Clic aquí.</a>
                        </p>
                        <a href="" className="btnLogin">
                            <img src="/img/icons/rightb.svg" alt="➡️"/>
                        </a>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Login