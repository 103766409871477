import React from 'react'

let vector = [0]
let validate = 1
let count = 0

const List = function list ({list,url,service}){
    return (
        <section className="services" style={{display:(service.id == 2?"none":"block")}}>
            <div className="content colflex">
                <div className="info">
                    <h2>SERVICIOS</h2>
                    <div className="slickServices" id="slickServices">
                        {list.map((l)=>{
                            count++
                            return(
                                <div className="item" key={l.id}>
                                    <div className="title flex">
                                        <p>{l.title}</p>
                                        <div className="badge relative">
                                            <span >{count}</span>
                                        </div>
                                    </div>
                                    <div className="txtServItem">
                                        <p dangerouslySetInnerHTML={{__html: l.text}}></p>
                                    </div>
                                    <span className="moreTxt">Ver más</span>
                                </div>
                            )
                        })}
                    </div>
                    {vector.map((v)=>{
                        if(list.length > 0 && validate == 1){
                        //if(validate == 1){
                            validate = 2
                            setTimeout(()=>{
                                $('#slickServices').slick({
                                    slidesToShow: 1,
                                    arrows: false,
                                    mobileFirst: true,
                                    centerMode: true,
                                    responsive: [
                                      {
                                        breakpoint: 700,
                                          settings: {
                                            centerMode: false
                                          }
                                      },
                                      {
                                        breakpoint: 1000,
                                          settings: 'unslick'
                                      },
                                    ]
                                });
                                const moreTxtElements = document.querySelectorAll('.moreTxt');
                                moreTxtElements.forEach((moreTxt) => {
                                    moreTxt.addEventListener('click', () => {
                                        const txtServItem = moreTxt.previousElementSibling;
                                        if (txtServItem.classList.contains('opened')) {
                                            // Si está expandido, volver al tamaño inicial
                                            //txtServItem.style.height = '8rem'
                                            txtServItem.classList.remove('opened')
                                            moreTxt.textContent = 'Ver más'
                                        } else {
                                            // Si no está expandido, mostrar todo el contenido
                                            txtServItem.classList.add('opened')
                                            moreTxt.textContent = 'Ver menos'
                                            //txtServItem.style.height = 'auto';
                                        }
                                    });
                                });
                            },10)
                        }
                    })}
                </div>
                <img src={url+service.worker} alt="worker-image"/>
            </div>
        </section>
    )
}

export default List