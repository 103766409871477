import React from 'react'

let vector = [0]

const Benefits = function benefits ({}){
    return (
        <section className="benefitsProd">
            <div className="container">
                
                <div className="item colflex">
                    <img src="/img/truck3.jpg" alt="image-ben"/>
                    <div className="text">
                        <h2>Water Truck Applications</h2>
                        <ul>
                            <li>Cat® Articulated Truck Bare Chassis are rugged and reliable platforms for water truck applications in both mining and construction projects.</li>
                            <li>Using an articulated truck bare chassis provides a flexible solution that is ideal for dust suppression, road construction, fire protection, and other applications.</li>
                            <li>Caterpillar works with OEMs worldwide to match various size water tanks with our bare chassis machines, all through your local Cat dealer, to provide the best solution for your business</li>
                        </ul>
                    </div>
                </div>
            
                <div className="item colflex">
                    <img src="/img/truck3.jpg" alt="image-ben"/>
                    <div className="text">
                        <h2>Water Truck Applications</h2>
                        <ul>
                            <li>Cat® Articulated Truck Bare Chassis are rugged and reliable platforms for water truck applications in both mining and construction projects.</li>
                            <li>Using an articulated truck bare chassis provides a flexible solution that is ideal for dust suppression, road construction, fire protection, and other applications.</li>
                            <li>Caterpillar works with OEMs worldwide to match various size water tanks with our bare chassis machines, all through your local Cat dealer, to provide the best solution for your business</li>
                        </ul>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default Benefits