import React from 'react'

let vector = [0]
var vector_pages = [];

const Content = function content ({
    url,text,list,
    prevHandler,nextHandler,currentPage,changeHandler,pages,
    openModal
    }){

    let pagination = ""
    let page = ""
    let count_pages = 0
    
    if(parseInt(pages) >= 1){
        let vector_pages = [];
        for (let i = 0; i<pages; i++){
            vector_pages[i] = i
        }
        pagination = vector.map((v)=>{
            return (
                <div className="paginator container flex" key={1}>
                    <img onClick={prevHandler} src="/img/icons/back.svg" alt="⬅️"/>
                    <div className="numbers flex">
                        {vector_pages.map((v)=>{
                            return (
                                <span
                                    className={currentPage == v?"selected":""}  
                                    onClick={() => changeHandler(v)} 
                                    key={v}
                                >
                                    {v+1}
                                </span>
                            )
                        })}
                    </div>
                    <img onClick={nextHandler} src="/img/icons/next.svg" alt="➡️"/>
                </div>
            )
        })
    }else{
        vector_pages = [];
        vector_pages[0] = 0
    }

    return (
        <section className="jobs">
            <div className="container colflex">
                <h1>{text.title}</h1>
                <p dangerouslySetInnerHTML={{__html: text.text}}></p>
                <div className="listJobs grid" id="allProducts">
                    {list.map((l)=>{
                        return(
                            <div className="item colflex" key={l.id}>
                                <div className="title flex">
                                    <img src={url+l.img} alt="img-job"/>
                                    <h3>{l.title}</h3>
                                </div>
                                <div className="resume colflex">
                                    <p>{l.text}</p>
                                </div>
                                <div className="actions flex">
                                    <a href={"/trabaje-con-nosotros/aplicar/"+l.id} className="flex">
                                        <span>Aplicar</span>
                                        <img src="/img/icons/righty.svg" alt="➡️"/>
                                    </a>
                                    <a onClick={()=>openModal(l.title,window.location.href)} className="flex openShareModal">
                                        <img src="/img/icons/share.svg" alt="share-icon"/>
                                    </a>
                                </div>
                            </div>
                        )
                    })}
                </div>

                {pagination}

            </div>
  
        </section>
    )
}

export default Content