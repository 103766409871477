import React from 'react'

let vector = [0]

const Header = function header ({products,services}){
    return (
        <div>
            <div className="separatorHeader" id="separatorHeader">
            </div>
            <header id="header" >
                <div className="container flex">
                    <a href="/" className="flex">
                        <img src="/img/logo2.svg" alt="logo" className="logo"/>
                    </a>
                    <nav className="dnone">
                        <button className="flex menuItem" id="menuItem1">
                            <span>Productos</span>
                            <svg width="13" height="8" viewBox="0 0 13 8" fill="none">
                            <path d="M1 1L6.5 6.5L12 1" stroke="white" stroke-width="2"/>
                            </svg>
                        </button>
                        <button className="flex menuItem" id="menuItem2">
                            <span>Servicios</span>
                            <svg width="13" height="8" viewBox="0 0 13 8" fill="none">
                            <path d="M1 1L6.5 6.5L12 1" stroke="white" stroke-width="2"/>
                            </svg>
                        </button>
                        <button className="flex menuItem" id="menuItem3">
                            <span>Nosotros</span>
                            <svg width="13" height="8" viewBox="0 0 13 8" fill="none">
                            <path d="M1 1L6.5 6.5L12 1" stroke="white" stroke-width="2"/>
                            </svg>
                        </button>
                        <button className="flex green menuItem" id="menuItem4">
                            <span >Esg</span>
                            <svg width="13" height="8" viewBox="0 0 13 8" fill="none">
                            <path d="M1 1L6.5 6.5L12 1" stroke="white" stroke-width="2"/>
                            </svg>
                        </button>
                    </nav>
                    <div className="actions flex">
                        <a href={'/acceder'} className="dnone loginBtn">
                            <span>Login</span>
                            <img src="/img/icons/log.svg" alt="user-icon"/>
                        </a>
                        <a className="flag spa dnone">
                            <img src="/img/icons/spa.svg" alt="flag-icon"/>
                            <span>ES</span>
                        </a>
                        <a className="flag eng dnone">
                            <img src="/img/icons/eng.svg" alt="flag-icon"/>
                            <span>EN</span>
                        </a>
                        <img src="/img/icons/menu.svg" alt="menu" className="menu" id="nav-toggle"/>
                    </div>
                </div>

                <div id="menuHead" className="menuHead">

                    <div id="menu1">
                        {products.map((l)=>{
                            if(l.id==1){
                                return(
                                    <div className="list colflex" key={l.id}>
                                        <p style={{display: "none"}}>
                                            {l.title}
                                        </p>
                                        <a href={'/productos/mineria/de-superficie'}>Minería de superficie</a>
                                        <a href={'/productos/mineria/subterranea'}>Minería subterránea</a>
                                    </div>
                                )
                            }else{
                                return(
                                    <div className="list colflex" key={l.id}>
                                        <a href={'/productos/'+l.slug}>
                                            {l.title}
                                        </a>
                                    </div>
                                )
                            }
                        })}
                    </div>

                    <div id="menu2">
                        <div className="list colflex pt1">
                            {services.map((l)=>{
                                if(l.id<=3){
                                    return(
                                        <a href={l.url} key={l.id}>{l.title}</a>
                                    )
                                }
                            })}
                        </div>
                    
                        <div className="list colflex pt2">
                            {services.map((l)=>{
                                if(l.id>=4){
                                    return(
                                        <a href={l.url} key={l.id}>{l.title}</a>
                                    )
                                }
                            })}
                        </div>
                    </div>

                    <div id="menu3">
                        <div className="list colflex pt1">
                            <a href={"/somos-relianz"}>Somos Relianz</a>
                            <a className="openFormMore">Contáctanos</a>
                            <a href={'/lo-ultimo'}>Lo último</a>
                        </div>
                    
                        <div className="list colflex pt2">
                            <a href={"/trabaje-con-nosotros"}>Trabaje con nosotros</a>
                            <a href={"/etica-cumplimiento"}>Ética y cumplimiento</a>
                            <a href={"/solicitudes-y-denuncias"}>Denuncias: Línea ética</a>
                        </div>
                    
                        <div className="list colflex pt3">
                            <a href={"/autorizacion-tratamiendo-de-datos-personales"}>
                                Tratamiento de datos personales
                            </a>
                        </div>
                    </div>

                    <div id="menu4">
                        <div className="list colflex pt1">
                            <a href={'/sostenibilidad'}>
                                Sostenibilidad
                            </a>
                        </div>
                        
                        <div className="list dnone pt2">
                            <a >Equidad e inclusión</a>
                        </div>
                    </div>

                </div>

                <div className="flagLong flex">
                    <div className="am"></div>
                    <div className="az"></div>
                    <div className="ro"></div>
                </div>
            </header>
            

        </div>
    )
}

export default Header