import React from 'react'

let vector = [0]

const Content = function content ({text,category,pdf,url}){
    return (
        <section className="ethics">
            <div className="container colflex">

                <div className="text colflex">
                    <h1>{text.title} <span>{text.subtitle}</span></h1>
                    <p dangerouslySetInnerHTML={{__html: text.text}}></p>
                </div>

                <div className="itemList colflex">

                    {category.map((c)=>{
                        const list = pdf.filter(
                            (p) => p.ethics_category_id === c.id,
                        );
                        return(
                            <div className="item colflex" key={c.id}>
                                <h3>{c.title}</h3>
                                <div className="blockList flex">
                                    {list.map((l)=>{
                                        return(
                                            <div className="colflex block" key={l.id}>
                                                <p>{l.text}</p>
                                                <a href={url+l.file} 
                                                target='_blank'
                                                download className="button flex">
                                                    <span>PDF</span>
                                                    <svg width="35" height="35" viewBox="0 0 40 40" fill="none" >
                                                    <circle cx="20" cy="20" r="20" transform="rotate(90 20 20)" fill="black"/>
                                                    <path d="M19.2929 28.7071C19.6834 29.0976 20.3166 29.0976 20.7071 28.7071L27.0711 22.3431C27.4616 21.9526 27.4616 21.3195 27.0711 20.9289C26.6805 20.5384 26.0474 20.5384 25.6569 20.9289L20 26.5858L14.3431 20.9289C13.9526 20.5384 13.3195 20.5384 12.9289 20.9289C12.5384 21.3195 12.5384 21.9526 12.9289 22.3431L19.2929 28.7071ZM19 12L19 28L21 28L21 12L19 12Z" fill="white"/>
                                                    </svg>
                                                </a>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    })}

                </div>
            </div>
        </section>
    )
}

export default Content