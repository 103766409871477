import React from 'react'

let vector = [0]

const Name = function name ({url,text}){
    return (
        <section className="nameSustain">
            <div className="container colflex">
                <div className="text colflex">
                    <h1><strong>{text.title}</strong> {text.subtitle}</h1>
                    <p dangerouslySetInnerHTML={{__html: text.text}}></p>
                </div>
                <iframe src={"https://www.youtube.com/embed/"+text.video}title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <img src={url+text.img} alt="sustain-image"/>
            </div>
        </section>
    )
}

export default Name