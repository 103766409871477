import React from 'react'

let vector = [0]

const V360 = function v360 ({}){
    return (
        <section className="v360">
            <div className="container">
            </div>
        </section>
    )
}

export default V360